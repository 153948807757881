@import "../variables.scss";
.question-accordion-container {
  .question-container {
    padding: 0 30px;
    .question-title-sub-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        position: relative;
        @media (max-width: $screenXs){
            flex-direction: column;
            flex-wrap: nowrap;
        }
        .question-accordion-head-iframe {
          height: 30vw;
          width: 50vw;
        }
        .question-image-preview-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $sencondaryBorderColor;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
        }
        .question-preview-image {
          max-width: 80%;
          max-height: 80%;
          border-radius: 5px;
        }
      .question-title-img {
        max-height: 300px;
        max-width: fit-content;
        object-fit: contain;
      }
      .question-option-container {
        padding: 0px 10px;
        padding-bottom: 25px;
      }
    }
    .question-incorrect-ans {
      padding-top: 15px;
      color: $accent-color-2;
    }
    .question-correct-ans {
      padding-top: 15px;
      color: $accent-color-3;
    }
    .question-accordion-answer-iframe {
      margin-top: 10px;
    }
  }
}
.title-subtitle {
  width: 100%;
}
.question-title-wrapper {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 5px;
  .question-title {
    color: $primary-color;
    font-size: $mediumFontSize;
    width: 100%;
    word-break:break-word;
  }
  .checked-icon {
    align-items: center;
    .edit-delete-wrapper {
      display: flex;
      gap: 10px;
      .button-component {
        border: none;
        padding: 2px 4px;
      }
    }
  }
  .checked-false {
    display: none;
  }
  .checked-true {
    display: flex;
  }
}
.question-checkbox {
  display: flex;
}
.question-submit-btn {
  width: 100px;
}
.question-submit-btn-wrapper {
  margin-top: 20px;
  padding-bottom: 15px;
}
.question-fillup-input {
  margin-top: 10px;
  width: 100%;
}
