@import "../variables.scss";

.modal-container {
  width: 100vw;
  height: 100vh;
  padding: 5%;
  z-index: 12;
  background-color: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
