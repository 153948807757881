@import "../variables.scss";

.user-status-card {
  display: flex;
  gap: 5px;
  padding: 0 5px;
  border-radius: 6px;
  background-color: $secondaryBgColor;
  height: min-content;
  width: fit-content;
  box-shadow: $box-shadow-light-dark 0px 0.301094px 0.301094px 1px;
  transition: all 1s;
  cursor: pointer;
  &:hover {
    background-color: $Gray43;
  }
  .content-text {
    padding: 5px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .title,
  .goal-text,.question-type-body {
    width: 150px;
    cursor: pointer;
  }
  .batchCode {
    width: 120px;
  }
  .name,
  .tagName,
  .time {
    width: 110px;
  }
}

.input-obtained-marks {
  width: 100%;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 8px;
  border-radius: 5px;
  &:focus {
    outline: none;
  }

  &:focus::-webkit-outer-spin-button,
  &:focus::-webkit-inner-spin-button,
  &:hover::-webkit-outer-spin-button,
  &:hover::-webkit-inner-spin-button {
    display: none;
  }
}

.save-marks {
  padding: 5px 8px;
  width: 100%;
  cursor: pointer;
  &:hover {
    border: 1px solid;
  }
  &:not([disabled]):hover {
    border: 1px solid;
  }
  &:disabled {
    background-color: gray;
    cursor: default;
  }
}
