.header-container-wrapper {
    width: 100%;
    height: 150px;
    padding: 50px;
}

.header-container {
    padding: 0px 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-title {
    font-size: 28px;
}

.header-first-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media only screen and (max-width: 992px) {
    .header-container {
        padding: 0;
    }
}