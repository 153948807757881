.faqComponent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    padding-bottom: 20px;
    @media(max-width: 991px) {
        padding: 80px 20px;
    }
}

.faqComponentWhite {
    background-color: #ffffff;
    color: #000;
}
.pricing-info-container{
    @media(min-width: 991px) {
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
    }
    .pricing-info-sub-container{
        width: 100%;
        max-width: 380px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 25px;
        border: 1px solid rgba(128, 128, 128, 0.203);
        background-color: rgb(246, 247, 249);
        position: relative;
        @media(min-width: 991px) {
            max-width: 50%;
        }

        .pricing-info-header{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .pricing-info-logo{
                width: 50px;
                height: 50px;
            }
            .pricing-info-rate{
                font-size: 12px;
                strong{
                    font-size: 20px;
                }
            }
        }
        .pricing-info-category{
            background-color: rgb(219, 255, 153);
            padding: 5px 10px;
            border-radius: 10px;
            width: fit-content;
            font-size: 12px;
        }
        .pricing-info-description{
            color: #666666;
            font-size: 12px;
        }
        .pricing-info-content-separator{
            border: 1px dashed rgb(209, 214, 224);
            margin: 10px 0px;
        }
        .pricing-info-feature-container{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .pricing-info-feature{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: rgb(51, 51, 51);
                border-bottom: 1px solid rgb(230, 233, 238);
                padding: 10px 0px;
                &:last-child, 
                &:first-child{
                    border: none;
                }
                .pricing-check-icon{
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    padding: 3px;
                    background-color: rgb(219, 255, 153);
                    border-radius: 5px;
                }
                .pricing-info-icon{
                    width: 16px;
                    height: 16px;
                }
                .pricing-p{

                    font-size: 9px;
                    color: #606060;
                    margin-top: 5px;
                    margin-bottom: 0;

                }
            }
        }
        .pricing-info-highlighted-message{
            position: absolute;
            top: 0px;
            padding: 5px 10px;
            background: linear-gradient(rgb(183, 255, 51) 0%, rgb(219, 255, 153) 100%);
            top: -10px;
            left: 20%;
            font-size: 12px;
            border-radius: 30px;
        }
    }
}

.pricing-info-main-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 20px 20px 20px 0px;
    @media(max-width: 991px) {
        align-items: center;
        max-width: 650px;
        width: 100%;
    }
    .pricing-info-icon {
      color: #333333;
      display: flex;
      align-items: center;
      font-size: 14px;
      border: 1px solid rgb(230, 233, 238);
      padding: 6px 10px;
      border-radius: 100px;
      background-color: rgb(248, 249, 250);
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .pricing-main-header {
      color: rgb(12, 12, 12);
      font-size: 40px;
      font-weight: 500;
      margin: 0;
      line-height: 110%;
    }
    .pricing-info-header-paragraph {
      word-wrap: break-word;
      color: #606060;
      font-weight: 400;
      font-size: 16px;
      margin: 0;
      text-align: center;
      @media (min-width: 992px) {
      text-align: left;
      }
    }
}