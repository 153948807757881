@import "../../components/variables.scss";
.main-daycontextpage-container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  background-color: $primaryBgColor;
  color: $primaryColor;
  align-items: center;
  gap: 20px;
  font-family: $secondaryFontFamily;

  .main-title-div {
    font-size: $headerFontSize;
    color: $primaryColor;
    font-weight: 700;
    padding: 10px 20px;
  }
  .content-navigation {
    display: flex;
    gap: 20px;
    font-size: $largerFontSize;
    width: 100%;
    align-items: center;
    justify-content: center;
    .navigator {
      color: $link-color;
      border-bottom: 2px solid transparent;
      transition: 0.3s;

      &:hover {
        border-color: $link-hover-color;
        cursor: pointer;
      }
    }
  }
  .context-content-wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .context-content-header {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $link-color;
      font-size: $subHeaderFontSize;
      @media (max-width: $screenLg) {
        width: 90%;
      }
      @media (max-width: $screenMd) {
        width: 100%;
      }
      .context-title {
        font-weight: 700;
      }
    }
    .video-note-wrapper {
      display: flex;
      flex-direction: column;
      width: 80%;
      justify-content: center;
      transition: 0.3s;
      gap: 20px;
      @media (max-width: $screenLg) {
        width: 90%;
      }
      @media (max-width: $screenMd) {
        width: 100%;
      }
      .video-note-content-wrapper {
        display: flex;
        flex-direction: column;
        background-color: $fadedBlackColor;
        border-radius: 20px;
        align-items: center;
        padding: 2px;
        .video-note-content {
          width: 100%;
          cursor: pointer;
          aspect-ratio: 16/9;
          .context-iframe {
            width: 100%;
            height: 100%;
            border-radius: 20px 20px 0px 0px;
          }
        }
        .content-text-wrapper {
          font-size: $mediumFontSize;
          padding: 10px;
          width: 100%;
          border-radius: 0px 0px 20px 20px;
          height: fit-content;
          .content-title {
            font-size: $largerFontSize;
            font-weight: 700;
            @media (max-width: $screenMd) {
              font-size: $mediumFontSize;
            }
          }
          @media (max-width: $screenMd) {
            font-size: $smallFontSize;
          }
        }
      }
    }
  }
}