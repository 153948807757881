.technology-overview-component {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    padding: 100px;
    padding-bottom: 20px;

    @media (max-width: 991px) {
        padding: 30px;
        padding: 80px 20px;
    }
    .technology-overview-head {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .technology-overview-head-h4{
            color: #333333;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            border: 1px solid rgb(230, 233, 238);
            padding: 6px 10px;
            border-radius: 100px;
            background-color: rgb(248, 249, 250);
        }
        .technology-overview-head-h3{
            font-size: 40px;
            font-weight: 500;
            color: #000;
            text-align: center;
            @media (max-width: 520px) {
                font-size: 28px;
            }
        }
        .technology-overview-head-p{
            font-size: 18px;
            font-weight: 300;
            color: #787878;
            text-align: center;

        }
    }

    .technology-overview-body {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-direction: row;
        gap: 10px;
        @media (max-width: 800px) {
            flex-direction: column;
            
        }

        .technology-overview-body-column {
            width: inherit;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-direction: column;
            gap: 10px;
           

            .technology-overview-body-column-row {
                width: 100%;
                height: 100%;
                background-color: #f7f9fa;
                border-radius: 16px;
                border: 1px solid #edeff2;
                padding: 22px;
               
                .technology-overview-body-column-row-top {
                    height: 48%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px;

                    .technology-overview-body-column-row-top-icon-container {
                        height: 50px;
                        width: 50px;
                        background-color: #dbff99;
                        border-radius: 12px;
                        overflow: hidden;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .technology-overview-body-column-row-top-icon{

                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }

                    }

                    .technology-overview-body-column-row-top-headline {
                        color: #000;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                .technology-overview-body-column-row-bottom {
                    height: 48%;
                    margin-top: 4%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .technology-overview-body-column-row-bottom-text {
                        color: #787878;
                        font-size: 15px;
                        font-weight: 300;
                    }
                }
            }
        }

        .technology-overview-body-column:nth-child(2) {
            overflow: hidden;
            background-color: #dbff99;
            border-radius: 16px;
            border: 1px solid #edeff2;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            @media (max-width: 1199px) {
                display: none;                
            }
        }
    }
}










