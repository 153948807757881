@import "../variables.scss";

.dashboard-class-component-container {
  width: 380px;
  max-height: 260px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #2B2F33;
  .current-class-header {
    color: #f9f8fa;
    font-size: 14px;
    font-weight: 600;
  }
  .current-class-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .current-class-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      background-color: rgba(66, 66, 66, 0.4);
      color: #262626;
      border-radius: 8px;
      justify-content: space-between;
      flex-direction: column;
      transition: all 0.3s ease;
      gap: 4px;
      &:hover {
        background: rgba(66, 66, 66, 0.7);
      }
      .current-class-topic-status-wrapper {
        width: 100%;
        display: flex;
        .current-class-topic {
          font-size: 14px;
          color: #f9f8fa;
          width: 100%;
          text-wrap: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .current-class-status {
          font-size: 14px;
          color: #f9f8fa;
          .current-class-upcoming {
            color: orange;
          }
          .current-class-completed {
            color: green;
          }
        }
      }
      .class-data-container {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        color: #f9f8fa;
        .class-data-topic {
          font-size: 10px;
          box-shadow: 0px 0px 2px rgb(80, 80, 81);
          padding: 4px 8px ;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover{
            transform: scale(.95);
            background-color: rgb(47, 47, 47);
          }
        }
        .class-data-date{
            font-size: 10px;
        }
      }
    }
  }
}
