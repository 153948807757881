@import "../variables.scss";

.code-block-container {
  width: 100%;
  display: flex;
  border-radius: 10px;
  margin: 10px 0 5px;
  border: 1px solid black;
  .code-block-container-fix-width{
    max-width: 50%;
  }
  .code-editor-blocks-container {
    flex: 1;

    .code-blocks-tabs {
      button {
        padding: 4px 8px;
        border-radius: 4px 4px 0 0;
        font-size: 14px;

        &.selected {
          background-color: $primary-color;
          color: $bacgroundBlackColor;
        }

        &.selected.html {
          background-color: $accent-color-2;
        }

        &.selected.css {
          background-color: $accent-color-1;
        }
        &.selected.js {
          background-color: $accent-color-4;
        }
      }
    }

    .code-editor-container {
      flex: 1;
      .CodeMirror {
        color: $primary-color;
        overflow: auto;
        resize:vertical;
        min-height: 300px;
        font-size: 14px !important;
      }

      .CodeMirror-gutters {
        background: $code-editory-bg !important;
        font-size: 14px !important;
      }

      .CodeMirror-scroll {
        background: $code-editory-bg;
        font-size: 14px !important;
      }
    }
  }

  .code-block-result-container {
     flex-basis: 50%;
     min-height: 300px;
  }
}

.code-block-buttons {
  width: 100%;
  margin-bottom: 10px;

  button {
    background-color: $accent-color-1;
    padding: 7px 22px;
    margin: 5px 5px 5px 0;
    border-radius: 4px;
    font-size: 14px;
    color: $primary-color;

    &:hover {
      background-color: $button-hover-bg;
    }

    &.reset-btn {
      background-color: $accent-color-2;
    }

    &.reset-btn:hover {
      background-color: $red;
    }
  }
}

@media (max-width: 824px) {
  .code-block-container {
    flex-direction: column;
    .code-block-container-fix-width{
      max-width: 100%;
  }
  }
}

.submitted-line{
  color: $accent-color-3;
  font-size: 12px;
  margin: 10px 0 5px;
}