@import "../variables.scss";
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .footer-info-panel-component {
        width: 100%;
        padding: 20px;
        background-color: $primaryColor;
        .footer-info-panel-container {
            width: 100%;
            border-radius: 15px;
            padding: 10px 20px 50px 20px;
            background-color: $black;

            .footer-info-panel-card-wrap {
                display: flex;
                align-items: start;
                justify-content: center;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 10px;

                .footer-info-panel-card-one {
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: 30px;
                    flex-wrap: wrap-reverse;

                    .footer-info-panel-card-one-logo {
                        position: relative;
                        background-color: #fff;
                        width: 80px;
                        height: 50px;
                        overflow: hidden;
                    }

                    .footer-info-panel-card-one-text-container {
                        .footer-info-panel-card-one-text {
                            font-size: $mediumFontSize;
                            color: $textLightGrey;
                        }
                    }

                    .footer-info-panel-card-one-social-media-wrap {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 17px;

                        .footer-info-panel-card-one-social-media {
                            cursor: pointer;
                            height: 50px;
                            width: 50px;
                            background-color: $darkGrey;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            padding: 10px;
                            &:hover{
                                background-color: $fadedGraySecondary;
                                }
                            .footer-info-panel-card-one-social-media-img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                .footer-info-panel-card-two {
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 60px;

                    .footer-info-panel-card-two-link-container {
                        display: flex;
                        align-items: start;
                        justify-content: start;
                        flex-direction: column;
                        gap: 10px;

                        .footer-info-panel-card-two-link-head {
                            color: $textLightGrey;
                            font-size: $mediumFontSize;
                            font-weight: 500;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-two-link {
                            font-size: $mediumFontSize;
                            font-weight: 300;
                            margin-bottom: 0;
                            color: $textGrey;
                            cursor: pointer;
                        }

                        .footer-info-panel-card-two-link:hover {
                            font-weight: 400;
                            color: $textLightGrey;
                            text-decoration: none;
                        }
                    }
                }

                .footer-info-panel-card-three {
                    margin-top: 30px;
                    width: 100%;

                    .footer-info-panel-card-three-contactLink {
                        width: 100%;
                        padding: 7px;
                        overflow: hidden;
                        border-radius: 15px;
                        border: 2px solid $darkGrey;

                        .footer-info-panel-card-three-contactLink-iframe {
                            border-radius: 8px 8px 0 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-three-location-map-link-container {
                            width: 100%;
                            padding: 4px;

                            .footer-info-panel-card-three-location-map-link {
                                display: flex;
                                align-items: start;
                                flex-direction: column;
                                margin-bottom: 0;

                                .contact-heading {
                                    color: $textLightGrey;
                                    font-size: $mediumFontSize ;
                                    font-weight: 500;
                                }

                                .contact-body {
                                    color: #f9f8fa;
                                    font-size: $mediumFontSize ;
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }

            .footer-info-panel-foot {
                width: 100%;
                display: flex;
                align-items: start;
                justify-content: start;
                margin-top: 20px;
                flex-wrap: wrap;
                border-top: 2px solid $darkGrey;
                padding: 20px 0;
                flex-direction: column;

                .footer-info-panel-foot-p {
                    color: $textLightGrey;
                    font-size: $mediumFontSize;
                    font-weight: 500;
                }

            }
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .footer-info-panel-component {
        width: 100%;
        padding: 20px;
        background-color: $primaryColor;
        .footer-info-panel-container {
            width: 100%;
            border-radius: 30px;
            padding: 50px 70px 10px 70px;
            background-color: $black;

            .footer-info-panel-card-wrap {
                display: flex;
                align-items: start;
                justify-content: center;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 10px;

                .footer-info-panel-card-one {
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: 30px;
                    flex-wrap: wrap-reverse;

                    .footer-info-panel-card-one-logo {}

                    .footer-info-panel-card-one-text-container {
                        .footer-info-panel-card-one-text {
                            font-size: $mediumFontSize;
                            color: $textLightGrey;
                        }
                    }

                    .footer-info-panel-card-one-social-media-wrap {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 17px;

                        .footer-info-panel-card-one-social-media {
                            cursor: pointer;
                            height: 50px;
                            width: 50px;
                            background-color: $darkGrey;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            padding: 10px;
                            &:hover{
                                background-color: $fadedGraySecondary;
                                }
                            .footer-info-panel-card-one-social-media-img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                .footer-info-panel-card-two {
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 60px;

                    .footer-info-panel-card-two-link-container {
                        display: flex;
                        align-items: start;
                        justify-content: start;
                        flex-direction: column;
                        gap: 10px;

                        .footer-info-panel-card-two-link-head {
                            color: $textLightGrey;
                            font-size: $mediumFontSize;
                            font-weight: 500;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-two-link {
                            font-size: $mediumFontSize;
                            font-weight: 300;
                            margin-bottom: 0;
                            color: $textGrey;
                            cursor: pointer;
                        }

                        .footer-info-panel-card-two-link:hover {
                            font-weight: 500;
                            color: $textLightGrey;
                            text-decoration: none;
                        }
                    }
                }

                .footer-info-panel-card-three {
                    margin-top: 30px;
                    width: 100%;

                    .footer-info-panel-card-three-contactLink {
                        width: 100%;
                        padding: 7px;
                        overflow: hidden;
                        border-radius: 15px;
                        border: 2px solid $darkGrey;

                        .footer-info-panel-card-three-contactLink-iframe {
                            border-radius: 8px 8px 0 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-three-location-map-link-container {
                            width: 100%;
                            padding: 4px;

                            .footer-info-panel-card-three-location-map-link {
                                display: flex;
                                align-items: start;
                                flex-direction: column;
                                margin-bottom: 0;

                                .contact-heading {
                                    color: $textLightGrey;
                                    font-size: $mediumFontSize ;
                                    font-weight: 500;
                                }

                                .contact-body {
                                    color: #f9f8fa;
                                    font-size: $mediumFontSize ;
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }

            .footer-info-panel-foot {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                flex-wrap: wrap;
                border-top: 2px solid $darkGrey;
                padding: 20px 0;

                .footer-info-panel-foot-p {
                    color: $textLightGrey;
                    font-size: $mediumFontSize;
                    font-weight: 500;
                }

            }
        }
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .footer-info-panel-component {
        width: 100%;
        padding: 20px;
        background-color: $primaryColor;

        .footer-info-panel-container {
            width: 100%;
            border-radius: 30px;
            padding: 50px 70px 10px 70px;
            background-color: $black;

            .footer-info-panel-card-wrap {
                width: 100%;
                display: flex;
                align-items: start;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;

                .footer-info-panel-card-one {
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    gap: 30px;
                    flex-wrap: wrap-reverse;

                    .footer-info-panel-card-one-logo {}

                    .footer-info-panel-card-one-text-container {
                        .footer-info-panel-card-one-text {
                            font-size: $mediumFontSize;
                            color: $textLightGrey;
                        }
                    }

                    .footer-info-panel-card-one-social-media-wrap {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 17px;

                        .footer-info-panel-card-one-social-media {
                            cursor: pointer;
                            height: 50px;
                            width: 50px;
                            background-color: $darkGrey;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            padding: 10px;
                            &:hover{
                                background-color: $fadedGraySecondary;
                                }
                            .footer-info-panel-card-one-social-media-img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                .footer-info-panel-card-two {
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 60px;

                    .footer-info-panel-card-two-link-container {
                        display: flex;
                        align-items: start;
                        justify-content: start;
                        flex-direction: column;
                        gap: 10px;

                        .footer-info-panel-card-two-link-head {
                            color: $textLightGrey;
                            font-size: $mediumFontSize;
                            font-weight: 500;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-two-link {
                            font-size: $mediumFontSize;
                            font-weight: 300;
                            margin-bottom: 0;
                            color: $textGrey;
                            cursor: pointer;
                        }

                        .footer-info-panel-card-two-link:hover {
                            font-weight: 400;
                            color: $textLightGrey;
                            text-decoration: none;
                        }
                    }
                }

                .footer-info-panel-card-three {
                    width: 100%;
                    margin-top: 30px;

                    .footer-info-panel-card-three-contactLink {
                        padding: 7px;
                        overflow: hidden;
                        border-radius: 15px;
                        border: 2px solid $darkGrey;

                        .footer-info-panel-card-three-contactLink-iframe {
                            border-radius: 8px 8px 0 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-three-location-map-link-container {
                            width: 100%;
                            padding: 4px;

                            .footer-info-panel-card-three-location-map-link {
                                display: flex;
                                align-items: start;
                                flex-direction: column;
                                margin-bottom: 0;

                                .contact-heading {
                                    color: $textLightGrey;
                                    font-size: $mediumFontSize ;
                                    font-weight: 500;
                                }

                                .contact-body {
                                    color: #f9f8fa;
                                    font-size: $mediumFontSize ;
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }

            .footer-info-panel-foot {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                flex-wrap: wrap;
                border-top: 2px solid $darkGrey;
                padding: 20px 0;

                .footer-info-panel-foot-p {
                    color: $textLightGrey;
                    font-size: $mediumFontSize;
                    font-weight: 500;
                }

            }
        }
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .footer-info-panel-component {
        width: 100%;
        padding: 20px;
        background-color: $primaryColor;

        .footer-info-panel-container {
            width: 100%;
            border-radius: 30px;
            padding: 50px 70px 10px 70px;
            background-color: $black;

            .footer-info-panel-card-wrap {
                width: 100%;
                display: flex;
                align-items: start;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;

                .footer-info-panel-card-one {
                    margin-top: 30px;
                    width: 100%;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 100vw;
                    gap: 30px;
                    flex-wrap: wrap-reverse;

                    .footer-info-panel-card-one-logo {}

                    .footer-info-panel-card-one-text-container {
                        .footer-info-panel-card-one-text {
                            font-size: $mediumFontSize;
                            color: $textLightGrey;
                        }
                    }

                    .footer-info-panel-card-one-social-media-wrap {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 17px;

                        .footer-info-panel-card-one-social-media {
                            cursor: pointer;
                            height: 50px;
                            width: 50px;
                            background-color: $darkGrey;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            padding: 10px;
                            &:hover{
                                background-color: $fadedGraySecondary;
                                }
                            .footer-info-panel-card-one-social-media-img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                .footer-info-panel-card-two {
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 60px;

                    .footer-info-panel-card-two-link-container {
                        display: flex;
                        align-items: start;
                        justify-content: start;
                        flex-direction: column;
                        gap: 10px;

                        .footer-info-panel-card-two-link-head {
                            color: $textLightGrey;
                            font-size: $mediumFontSize;
                            font-weight: 500;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-two-link {
                            font-size: $mediumFontSize;
                            font-weight: 300;
                            margin-bottom: 0;
                            color: $textGrey;
                            cursor: pointer;
                        }

                        .footer-info-panel-card-two-link:hover {
                            font-weight: 400;
                            color: $textLightGrey;
                            text-decoration: none;
                        }
                    }
                }

                .footer-info-panel-card-three {
                    margin-top: 30px;

                    .footer-info-panel-card-three-contactLink {
                        padding: 7px;
                        overflow: hidden;
                        border-radius: 15px;
                        border: 2px solid #333333;

                        .footer-info-panel-card-three-contactLink-iframe {
                            border-radius: 8px 8px 0 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-three-location-map-link-container {
                            width: 100%;
                            padding: 4px;

                            .footer-info-panel-card-three-location-map-link {
                                display: flex;
                                align-items: start;
                                flex-direction: column;
                                margin-bottom: 0;

                                .contact-heading {
                                    color: $textLightGrey;
                                    font-size: $mediumFontSize ;
                                    font-weight: 500;
                                }

                                .contact-body {
                                    color: #f9f8fa;
                                    font-size: $mediumFontSize ;
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }

            .footer-info-panel-foot {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                flex-wrap: wrap;
                border-top: 2px solid $darkGrey;
                padding: 20px 0;

                .footer-info-panel-foot-p {
                    color: $textLightGrey;
                    font-size: $mediumFontSize;
                    font-weight: 500;
                }
            }
        }
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .footer-info-panel-component {
        width: 100%;
        padding: 20px;
        background-color: $primaryColor;

        .footer-info-panel-container {
            width: 100%;
            border-radius: 30px;
            padding: 50px 70px 10px 70px;
            background-color: $black;

            .footer-info-panel-card-wrap {
                width: 100%;
                display: flex;
                align-items: start;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;

                .footer-info-panel-card-one {
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    flex-direction: column;
                    gap: 30px;
                    flex-wrap: wrap-reverse;

                    .footer-info-panel-card-one-logo {}

                    .footer-info-panel-card-one-text-container {
                        .footer-info-panel-card-one-text {
                            font-size: $mediumFontSize;
                            color: $textLightGrey;
                        }
                    }

                    .footer-info-panel-card-one-social-media-wrap {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 17px;

                        .footer-info-panel-card-one-social-media {
                            cursor: pointer;
                            height: 50px;
                            width: 50px;
                            background-color: $darkGrey;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            padding: 10px;
                            &:hover{
                            background-color: $fadedGraySecondary;
                            }
                            .footer-info-panel-card-one-social-media-img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                .footer-info-panel-card-two {
                    margin-top: 30px;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 60px;

                    .footer-info-panel-card-two-link-container {
                        display: flex;
                        align-items: start;
                        justify-content: start;
                        flex-direction: column;
                        gap: 10px;

                        .footer-info-panel-card-two-link-head {
                            color: $textLightGrey;
                            font-size: $mediumFontSize;
                            font-weight: 500;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-two-link {
                            font-size: $mediumFontSize;
                            font-weight: 300;
                            margin-bottom: 0;
                            color: $textGrey;
                            cursor: pointer;
                        }

                        .footer-info-panel-card-two-link:hover {
                            font-weight: 400;
                            color: $textLightGrey;
                            text-decoration: none;
                        }
                    }
                }

                .footer-info-panel-card-three {
                    margin-top: 30px;

                    .footer-info-panel-card-three-contactLink {
                        padding: 7px;
                        overflow: hidden;
                        border-radius: 15px;
                        border: 2px solid $darkGrey;

                        .footer-info-panel-card-three-contactLink-iframe {
                            border-radius: 8px 8px 0 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            margin-bottom: 0;
                        }

                        .footer-info-panel-card-three-location-map-link-container {
                            width: 100%;
                            padding: 4px;

                            .footer-info-panel-card-three-location-map-link {
                                display: flex;
                                align-items: start;
                                flex-direction: column;
                                margin-bottom: 0;

                                .contact-heading {
                                    color: $textLightGrey;
                                    font-size: $mediumFontSize ;
                                    font-weight: 500;
                                }

                                .contact-body {
                                    color: #f9f8fa;
                                    font-size: $mediumFontSize ;
                                    font-weight: 300;
                                }
                            }
                        }                       
                    }
                }
            }

            .footer-info-panel-foot {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                flex-wrap: wrap;
                border-top: 2px solid $darkGrey;
                padding: 20px 0;

                .footer-info-panel-foot-p {
                    color: $textLightGrey;
                    font-size: $mediumFontSize;
                    font-weight: 500;
                }
            }
        }
    }
}
.footer-info-panel-card-one-logo {
    position: relative;
    background-color: #fff;
    width: 80px;
    height: 55px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding:16px 8px;
    .webmaster-logo{
        transform: scale(1.8);
        position: absolute;
        top: 8px;
    }
}