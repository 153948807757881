@import "../../components/variables.scss";
.installment-list-container {
  width: 1100px;
  .installment-container {
    display: flex;
    margin-top: 20px;
    background-color: $greyContainerColor;
    border-radius: 6px;
    padding: 24px;
    height: 260px;

    .intalment-side-container {
      min-width: 150px;
      min-height: 100px;
      background-color: $greyInnerBoxColor;
      border-radius: 6px;
      text-align: center;
      padding: 4px;
    }
    .installment-meta-wrapper {
      margin-left: 10px;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
        height: 5px;
        border-radius: 2px;
      }
    }
  }
}
.installment-meta-content-wrapper {
  width: 100%;
}
.meta-wrapper-container {
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  width: 100%;
  .meta-tab-container {
    width: 100%;
    height: fit-content;
    display: flex;
    .tab-header {
      margin-inline: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-align: start;
      width: 100%;
    }
  }
}
.content-wrapper {
  height: 180px;
  min-width: fit-content;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    color: $primaryColor;
    border-radius: 2px;
  }
}
.content-container {
  display: flex;
  height: 56px;
  margin: 5px;
  transition: 0.9s ease;
  border-radius: 3px;
  &:hover {
    background-color: #2f2f2f;
    border-radius: 6px;
  }
  .payment-status-container {
    width: 100%;
    height: 56px;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    color: $installmentPageSecondaryFont;
    text-align: left;
    &.pending {
      .status-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $glowingYellow;
        color: $glowingYellow;
        padding: 2px 6px;
        font-size: $extraSmallFontSize;
        border-radius: 3px;
        width: 80px;
      }
    }
    &.rejected {
      .status-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $glowingRed;
        color: $glowingRed;
        padding: 2px 6px;
        font-size: $extraSmallFontSize;
        border-radius: 3px;
        width: 80px;
      }
    }
    &.approved {
      .status-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $glowingGreen;
        color: $glowingGreen;
        padding: 2px 6px;
        font-size: $extraSmallFontSize;
        border-radius: 3px;
        width: 80px;
      }
    }
  }
  .image-preview-container {
    padding: 5px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 120px;
    .receipt-preview {
      cursor: pointer;
      width: 100px;
      height: 40px;
      object-fit: cover;
      border-radius: 3px;
    }
  }
}
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $sencondaryBorderColor;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $screenXs) {
    width: 95vw;
  }
  .preview-image {
    max-width: 85%;
    max-height: 85%;
    border: 1px solid $primaryColor;
    border-radius: 4px;
  }
}
