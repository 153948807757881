.nav-user-component {
    // width: 150px;
    display: flex;
    align-items: end;
    justify-content: end;
    position: relative;
    flex-shrink: 0;

    .nav-user-dp {
        margin-right: 20px;
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ABFF1C;

        .containerImage {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .nav-user-drop-down {
        margin-top: 20px;
        padding: 10px;
        text-align: left;
        position: absolute;
        border: 0;
        height: auto;
        top: 100%;
        background-color: #474747;
        border-radius: 5px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 5px;
        flex-direction: column;

        .nav-user-drop-down-logout,
        .nav-user-drop-down-Dashboard {
            color: #fff;
            cursor: pointer;
            margin-bottom: 0;
            text-decoration: none;
        }
    }
}