@import "../../components/variables.scss";

.video-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .video-page-sub-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        max-width: $screenLg;
        background-color: $primaryBgColor;
        color: $primaryColor;
        font-family: $secondaryFontFamily;
        gap: 20px;
        align-items: center;
        overflow-x: hidden;
        padding: 20px 10px;
        @media (max-width: 300px) {
            overflow-x: visible;
        }
        .spinner-container{
            position: static;
        }
        .content-title {
            width: 100%;
            font-size: $headerFontSize;
            text-align: center;
            letter-spacing: 5px;
            font-weight: 700;
        }
        .videos-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-wrap: wrap;
        }
    }
}
