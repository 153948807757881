@keyframes hoverAnimationOne {
    0% {
        top: 50%;
        right: 50%;
    }

    100% {
        top: -120%;
        right: -120%;
    }
}

@keyframes hoverAnimationTwo {
    0% {
        top: 120%;
        right: 120%;
    }

    100% {
        top: 50%;
        right: 50%;
    }
}

@keyframes leaveAnimationOne {
    0% {
        top: -120%;
        right: -120%;
    }

    100% {
        top: 50%;
        right: 50%;
    }
}

@keyframes leaveAnimationTwo {
    0% {
        top: 50%;
        right: 50%;
    }

    100% {
        top: 120%;
        right: 120%;
    }
}


.elevate-page-component {
    padding: 100px;
    background-color: #fff;

    @media (max-width:991px) {
        padding: 30px
    }

    .elevate-page-head {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 50px;

        @media (max-width:591px) {
            margin-bottom: 30px;
        }

        .elevate-page-head-h4 {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #000;
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1px;
            padding: 6px 10px;
            border: 1px solid #e6e9ed;
            border-radius: 30px;
            background-color: rgb(248, 249, 250);
            width: fit-content;
        }

        .elevate-page-head-h1 {
            color: #000;
            font-size: 40px;
            font-weight: 500;
            margin-top: 20px;
            margin-bottom: 0;

            @media (max-width: 520px) {
                font-size: 28px;
            }
        }

        .elevate-page-head-p {
            font-size: 18px;
            font-weight: 300;
            color: #787878;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .elevate-page-container {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .elevate-card {
            background-color: #f7f9fa;
            border-radius: 24px;
            border: 1px solid #e6e9ed;
            height: 300px;
            box-shadow: rgb(128, 128, 128) 3px 3px 16px -10px;
            width: calc((100vw - 200px - 40px) /3);
            flex-shrink: 0;
            padding: 10px;
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;

            @media (max-width:991px) {
                width: calc((100vw - 40px - 40px) /2);
            }

            @media (max-width:791px) {
                width: calc(100vw - 40px - 20px);
            }

            .elevate-card-text-container {
                width: 100%;
                padding: 10px;
                display: flex;
                align-items: start;
                justify-content: space-between;

                .elevate-card-text-left {

                    width: inherit;

                    .elevate-card-text-h4 {
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        margin: 0;
                        color: #000;
                    }

                    .elevate-card-text-p {
                        font-size: 14px;
                        font-weight: 300;
                        margin: 0;
                        color: rgb(102, 102, 102);
                        margin-top: 7px;
                    }
                }

                .arrow-container {
                    height: 40px;
                    width: 40px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid #e6e9ed;
                    flex-shrink: 0;
                    position: relative;
                    overflow: hidden;

                    .arrow-image-one {
                        position: absolute;
                        transform: translate(50%, -50%);
                        right: 50%;
                        top: 50%;
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        animation: none;
                    }

                    .arrow-image-two {
                        position: absolute;
                        transform: translate(50%, -50%);
                        right: 120%;
                        top: 120%;
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        animation: none;
                    }
                }
            }

            &:hover {
                cursor: pointer;
                background-color: #f7f9fa;
                // box-shadow: rgb(128, 128, 128) 3px 3px 16px -3px;
                .elevate-card-text-container {
                    .arrow-container {
                        .arrow-image-one {
                            animation: hoverAnimationOne .5s forwards;
                        }

                        .arrow-image-two {
                            animation: hoverAnimationTwo .5s forwards;
                        }

                    }
                }
                .elevate-card-image-container {
                    .elevate-card-image{
                        height: 110%;
                        width: 110%;
                        transition: .5s;
                    }
                }
            }

            &:not(:hover) {
                .elevate-card-text-container {
                    .arrow-container {
                        .arrow-image-one {
                            animation: leaveAnimationOne  .5s forwards;
                        }

                        .arrow-image-two {
                            animation: leaveAnimationTwo .5s forwards;
                        }
                    }
                }
                .elevate-card-image-container {
                    .elevate-card-image{
                        height: 100%;
                        width: 100%;
                        transition: .5s;
                    }
                }
              
            }



            .elevate-card-image-container {
                width: 100%;
                height: inherit;
                background-color: rgb(216, 255, 177);
                border-radius: 14px;
                overflow: hidden;
                border: 1px solid #e6e9ed;
                position: relative;

                .elevate-card-image {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }



        }

        .elevate-card:nth-child(1) {
            width: calc(((100vw - 200px - 10px) /3)*2);

            @media (max-width:991px) {
                width: calc(100vw - 40px - 20px);
            }
        }
    }


}