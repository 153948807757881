@import "../variables.scss";

.reject-goal-feedback-container {
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: $primaryColor;
    padding: 30px;
    border-radius: 5px;
    max-height: 450px;
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
    }

    .goal-feedback-input-wrapper {
        display: flex;
        flex-direction: column;

        .feedback-label {
            font-size: $mediumFontSize;
            font-weight: 600;
            color: $black;
        }

        .feedback-text-conatiner {
            column-span: 10;
            padding: 10px;
            width: 100%;
            resize: vertical;
            border-radius: 10px;
            border: none;
            box-shadow: 0px 0px 2px 1px $black;

            &:focus {
                outline: none;
            }

            color: $black;
            letter-spacing: 0.5px;
        }
    }

    .reject-button {
        min-width: 100px;
        height: 30px;
        border: none;
        box-shadow: $boxShadowSecondaryColor 0px 0.301094px 0.301094px 1px;
        padding: 6px 8px;
        font-size: $smallFontSize;
        transition: all 1s;

        &:hover {
            background-color: $red;
            color: $primaryColor;
        }
    }
}