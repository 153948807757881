.about-us-component {
    width: 100%;
    padding: 40px 80px;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    padding: 100px;
    padding-bottom: 20px;
    @media (max-width: 991px) {
        padding: 80px 20px;
        padding: 30px;
    }

    .about-us-top {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-direction: row;
        gap: 40px;
        @media (max-width:991px) {
            flex-direction: column;
            gap: 0px;
        }

        .about-us-top-text-container {
            width: 50%;
            padding: 20px 10px;
            @media (max-width:991px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            .about-us-top-text-h4 {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1px;
                padding: 6px 10px;
                border: 1px solid #e6e9ed;
                border-radius: 30px;
                background-color: rgb(248, 249, 250);
                width: fit-content;
            }

            .about-us-top-text-h1 {
                font-size: 40px;
                font-weight: 500;
                margin-top: 20px;
                margin-bottom: 0;
                @media (max-width: 520px) {
                    font-size: 28px;
                }
            }
            .about-us-top-text-h5 {
                font-size: 18px;
                font-weight: 500;
                // color: #787878;
                margin-top: 20px;
                margin-bottom: 0;
            }

            .about-us-top-text-p {
                font-size: 16px;
                font-weight: 300;
                color: #787878;
                margin-top: 20px;
                margin-bottom: 0;
            }

            .about-us-top-text-contact-us-button {
                background-color: #000;
                color: #fff;
                padding: 14px 20px;
                border-radius: 10px;
                font-size: 14px;
                letter-spacing: 1px;
                margin-top: 20px;
            }

            .about-us-top-text-advancement-container {
                text-align: left;
                display: flex;
                align-items: stretch;
                justify-content: stretch;
                margin-top: 40px;
                @media (max-width:525px) {
                    flex-direction: column;
                }

                .about-us-top-text-advancement-container-up {
                    display: flex;
                    padding: 5px 0;

                    .about-us-top-text-advancement-card {
                        border-left: 1px solid #e6e9ed;
                        padding-left: 20px;
                        width: 120px;
                        @media (min-width:992px) and (max-width:1091px) {
                            width: 100px;
                            padding-right: 10px;
                        }

                        .about-us-top-text-advancement-card-text {
                            .about-us-top-text-advancement-card-text-h4 {
                                font-size: 28px;
                                margin-bottom: 0;
                                color: #000;
                            }

                            .about-us-top-text-advancement-card-text-h5 {
                                font-size: 14px;
                                font-weight: 400;
                                margin-bottom: 0;
                                color: #787878;
                            }
                        }
                    }

                    .about-us-top-text-advancement-card:nth-child(1) {
                        border-left: 0;
                        padding-left: 0;
                        @media (max-width:525px) {
                            padding-left: 20px;
                        }
                    }
                }

                .about-us-top-text-advancement-container-down {
                    display: flex;
                    padding: 5px 0;

                    .about-us-top-text-advancement-card {
                        border-left: 1px solid #e6e9ed;
                        padding-left: 20px;
                        width: 120px
                    }
                    @media (max-width:525px) {
                        border-top: 1px solid #e6e9ed;

                        .about-us-top-text-advancement-card:nth-child(1) {
                            border-left: 0;
                        }
                    }

                    .about-us-top-text-advancement-card-text {
                        .about-us-top-text-advancement-card-text-h4 {
                            font-size: 28px;
                            margin-bottom: 0;
                            color: #000;
                        }

                        .about-us-top-text-advancement-card-text-h5 {
                            font-size: 14px;
                            font-weight: 400;
                            margin-bottom: 0;
                            color: #787878;
                            flex-shrink: 0;

                        }
                    }
                }
            }
        }

        .about-us-top-image-container {
            border-radius: 20px;
            width: 50%;
            background-color: #dbff99;
            overflow: hidden;
            @media (max-width:991px) {
                width: 100%;
                height: 70vh;
                margin-top: 30px;
            }
            @media(max-width:891px) {
                height: 50vh;

            }
            @media (max-width:525px) {
                height: 30vh;
            }
            .about-us-top-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .about-us-bottom {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 20px;
        @media (max-width:791px) {
            flex-direction: column;
            justify-content: stretch;

        }
        .about-us-bottom-card {
            border: 1px solid #e6e9ed;
            border-radius: 16px;
            width: 100%;
            padding: 20px;
            box-shadow: rgb(128, 128, 128) 3px 3px 16px -10px;
            h4 {
                margin-bottom: 0;
                font-size: 17px;
                font-weight: 500;
            }
            .about-us-bottom-card-p {
                font-size: 16px;
                font-weight: 300;
                margin-top: 10px;
                color: #787878;
                margin-bottom: 0;
            }
        }
    }
}