@import "../variables.scss";

.goal-info-card-modal-main-container {
    background-color: $primaryModalBgColor;
    width: 100%;
}

.goal-info-card-main-wrapper {
    background-color: $primaryColor;
    width: 100%;
    max-width: 650px;
    padding-top: 5px;
    border-radius: 5px;
    max-height: 450px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
    }
}

.goal-info-card-main-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    padding-top: 0;
}

.goal-info-card-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.goal-modal-close-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    right: 0;
    padding-right: 4px;

    .goal-modal-close-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        border-radius: 2px;
        z-index: 5;
        padding: 3px;
        transition: background-color 0.2s;
        &:hover {
            background-color: $boxShadowWhiteColor;
        }
    }
}

.goal-info-card-title {
    font-size: $subHeaderFontSize;
    width: 70%;
    font-weight: 600;

    @media (max-width: $screenSm) {
        font-size: $titleFontSize;
    }
}