.blog-section {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 60px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 15px;
  background-color: #fff;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 100px;
  padding-bottom: 20px;
  @media(max-width: 991px) {
    padding: 80px 20px;
  }

  .blog-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 14px;
    justify-content: center;
    max-width: 680px;
    padding: 0;
    width: 100%;

    .blog-icon {
      color: #333333;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      border: 1px solid rgb(230, 233, 238);
      padding: 6px 10px;
      border-radius: 100px;
      background-color: rgb(248, 249, 250);
      margin-top: 10px;
    }

    .blog-main-header {
      font-size: 40px;
      color: #0c0c0c;
      font-weight: 500;
      margin: 0;
      text-align: center;
    }

    .blog-header-paragraph {
      font-size: 17px;
      color: #666666;
      max-width: 600px;
      word-wrap: break-word;
      font-weight: 400;
      margin: 0;
      text-align: center;
    }
  }

  .blog-grid {
    display: grid;
    gap: 20px;
    grid-auto-rows: minmax(0, 1fr);
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(200px, 1fr));
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, minmax(200px, 1fr));
    }

    .blog-card {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 16px;
      border-radius: 24px;
      overflow: hidden;
      transition: transform 0.2s;
      border: 1px solid rgb(230, 233, 238);
      padding: 8px;
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.03);
        .blog-card-mask-container {
          opacity: 1;
        }
        .blog-image-container {
            .open-blog-container {
                opacity: 1;
            }

          }
      }
      .blog-card-mask-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(203, 217, 228, 0.129);
        opacity: 0;
      }

      .blog-image-container {
        position: relative;
        width: 100%;
        .blog-image {
          aspect-ratio: 1.9/1;
          width: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
        .open-blog-container {
          transform: translate(-50%, -50%);
          align-items: center;
          display: flex;
          gap: 0px;
          height: min-content;
          justify-content: flex-start;
          left: 50%;
          min-height: 33px;
          overflow: visible;
          padding: 0;
          position: absolute;
          top: 50%;
          opacity: 0;
          transition: all .3s ease;
          .open-blog-text {
            background-color: rgb(255, 255, 255);
            border-radius: 100px;
            padding: 6px 16px;
            display: flex;
            gap: 10px;
            height: 33px;
            justify-content: center;
            align-items: center;
            color: rgb(38, 38, 38);
            font-size: 14px;
            line-height: 150%;
            font-weight: 400;
          }
          .open-blog-arrow-icon{
            aspect-ratio: 1/1;
            background-color: #fff;
            border-radius: 50%;
            width: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -27px;
          }
        }
      }
      .blog-content-footer-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 20px;
        height: 100%;
        justify-content: space-between;
        padding: 0 16px 16px;
      }
      .blog-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 4px;
        justify-content: flex-start;

        .blog-title {
          font-size: 16px;
          font-weight: 500;
          color: rgb(38, 38, 38);
          line-height: 150%;
          margin: 0;
        }

        .blog-description {
          margin: 0;
          text-overflow: ellipsis;
          text-align: left;
          color: rgb(102, 102, 102);
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 1.5;
        }
      }
      .blog-content-dash-line {
        width: 100%;
        border: none;
        border-top: 1px dashed rgb(225, 228, 235);
      }
      .blog-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #999;
        width: 100%;
        .blog-author-details {
          display: flex;
          gap: 14px;
          align-items: center;
          .blog-author-image {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            object-fit: cover;
            aspect-ratio: 1 / 1;
            border:1px solid #cecece;
          }
          .blog-author-name {
            word-break: break-word;
            word-wrap: break-word;
            font-size: 16px;
            color: rgb(61, 61, 61);
            font-weight: 400;
            line-height: 150%;
          }
        }
        .blog-read-time {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          flex-direction: row;
          background-color: rgb(248, 249, 250);
          border: 1px solid rgb(237, 239, 243);
          border-radius: 8px;
          padding: 4px 8px;
          color: rgb(61, 61, 61);
        }
      }
    }
  }

  .blog-footer-button {
    .view-all-blogs {
      background-color: #f8f9fa;
      color: rgb(25, 25, 25);
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      border: 1px solid rgb(230, 233, 238);
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      transition: background-color 0.3s;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: all 0.3s ease;
      &:hover {
        .blog-arrow-icon {
          margin-right: 0;
          margin-left: 10px;
        }
      }
      .blog-arrow-icon {
        transition: all 0.3s ease;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 809px) {
  .blog-section {
    gap: 50px;
  }
}
