@import "../../../components/variables.scss";

.create-goal-select-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    .create-goal-label {
      font-size: $extraSmallFontSize;
      .create-goal-label-required {
        color:$red;
        background:transparent;
      }
    }
    .create-goal-select {
      appearance: none;
      outline: none;
      border: none;
      border-radius: 6px;
      font-size: $smallFontSize;
      font-family: Inter, sans-serif;
      letter-spacing: 0em;
      line-height: 24px;
      text-align: left;
      font-weight: 400;
      background: $greyContainerColor;
      color: $installmentPageButton;
      box-shadow: $box-shadow-inset;
      .select {
        padding: 5px;
        .select-box {
          .options-container {
            z-index: 1;
            .list-child {
              background-color: $primaryBgColor;
              color: $primaryColor;
            }
          }
        }
      }
    }
  }