.mobileViewHeadNav {
    padding: 20px 10px 20px 10px;
    width: 100%;

    .mobile-headNavBar-box {
        background-color: rgba(26, 26, 26, 0.7);
        border-radius: 15px;
        padding: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        backdrop-filter: blur(5px);
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px 0px;

        .mobile-webmaster-logo {
            width: 80px;
            height: 50px;
            overflow: hidden;
            display: flex;
            align-items: center;
            background-color: white;
            padding: 8px;
            border-radius: 8px;
            box-shadow: 0px 0px 1px white;
        }

        .mobile-apply-button-container {
            display: flex;
            align-items: end;
            justify-content: end;
            margin-left: -10px;

            .apply-now-button-container {
                box-shadow: 0px 1px 2px #8b948f;
            }
            .mobile-apply-dp {
                background-color: #ABFF1C;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                overflow: hidden;
                .containerImage {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .nav-button {
            background-color: white;
            padding: 12px 18px 12px 18px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .nav-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                width: 30px;
                height: 30px;
            }

            .nav-icon-cross-button {
                transform: rotate(90deg);
            }
        }

        .nav-option-container {
            overflow: scroll;
            background-color: rgb(26, 26, 26, 0.97);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            justify-content: space-between;
            backdrop-filter: blur(5px);
            position: absolute;
            right: 0;
            top: 103%;
            width: 200px;
            height: 0;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            padding: 30px;

            .mobile-nav-buttons {
                color: rgb(255, 255, 255);
                font-size: 20px;
                transition: all 0.5s ease-in-out;
                cursor: pointer;
                font-weight: 400;
                font-size: 15px;
            }

            .mobile-active-nav {
                color: #ABFF1C;
            }

            .mobile-a {
                text-decoration: none;
                color: #fff;
            }

            .mobile-login-button {
                background-color: #ABFF1C;
                padding: 12px 12px 12px 12px;
                text-align: center;
                border-radius: 10px;
                cursor: pointer;
                transition: all 0.5s, ease-in-out;
                width: 80px;
                margin-top: 10px;
                box-shadow: 2px 2px 4px #000000;
            }

            .mobile-logout-button {
                background-color: #ff201c;
                padding: 12px 12px 12px 12px;
                text-align: center;
                border-radius: 10px;
                cursor: pointer;
                transition: all 0.5s, ease-in-out;
                width: 80px;
                margin-top: 10px;
                box-shadow: 2px 2px 4px #000000;
            }
        }

        .nav-option-container-active {
            height: 300px;
            opacity: 1;
        }
    }
}

.logo-wrapper {
    transform: scale(1.6);
    position: absolute;
    top: 13px;
}