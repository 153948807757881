@import "../../components/variables.scss";
.question-page-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .question-page-sub-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 80%;
    padding: 10px;
    gap: 20px;
    max-width: $screenLg;
    background-color: $primaryBgColor;
    overflow-x: scroll;
    @media (max-width: $screenMd) {
      width: 90%;
    }
    .question-time {
      font-size: 20px;
      margin-top: 5px;
      font-weight: 500;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      .question-page-description {
        width: 100%;
        display: flex;
        font-size: $largerFontSize;
        padding: 0px 0px;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
      }
      .question-day {
        margin-left: 30px;
      }
    }
    .questions-page-header {
      width: 100%;
      display: flex;
      justify-content: left;
    }
    .question-page-sub-container {
      height: 40px;
      width: 100%;
      display: flex;
      gap: 14px;
      flex-direction: column;
      .accordian-customize {
        padding: 12px 0;
      }
    }
  }
}
.question-category-card {
  background-color: $primaryColor;
  color: $bacgroundBlackColor;
  border-radius: 25px;
  padding: 10px 15px;
  display: flex;
  min-width: fit-content;
  align-items: center;
}
.question-pagination-container{
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding-bottom: 40px;
  @media(max-width: $screenXs){
    flex-direction: column-reverse;
  }
  
  .question-pagination-limit-selector{
    width: 150px;
    background-color: $tertiaryBgColor;
    color: $secondaryColor;
    border-radius: 5px;
  }
}
