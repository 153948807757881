@import '../../components/variables.scss';

.button-component {
  position: relative;
  padding: 15px 20px 15px 20px;
  border-radius: 3px;
  border: none;
  text-align: center;
  cursor: pointer;
  background-color: $primaryBg;
  color: $sencondaryBg;
  font-weight: bold;
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
 
  &:not([disabled]):hover{
      border: none;
  }
  .loader-postion-style {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 10px;
    .loader-icon {
      width: fit-content;
      height: 30px;
    }
  }
  .icon-position-center {
    display: flex;
    align-items: center;
    justify-content: center;
    .loader-button {
      display: none;
    }
  }
  .icon-position-left {
    .loader-left-button {
      display: none;
    }
  }
  .icon-position-right {
    .loader-right-button {
      display: none;
    }
  }
  .count-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $green;
    border-radius: 50%;
    padding: 4px 10px;
  }

  .count-label-outside {
    margin-left: 8px; 
    border: 1px solid $green;
    border-radius: 50%;
    padding: 5px 11px;
  }
}
.contained-button-style {
  background-color: $sencondaryBg;
  color: $primaryBg;
}
.outlined-button-style {
  border: 1px solid $primaryBorderColor;
}
.text-button-style {
  border: none;
  &:not([disabled]):hover{
      background-color: $primaryBg;
      font-size: 15px;
      color:$sencondaryBg;
  }
}

.small-fontsize {
  font-size: $smallFontSize;
}
.medium-fontsize {
  font-size: $mediumFontSize;
}
.large-fontsize {
  font-size: $largerFontSize;
}
