@import "../variables.scss";
.sidebar-container-wrapper{
    .sidebar-container-heading{
         color: $primaryTextColor;
    }
}

@media screen and (max-width: $screenXs) {
    .sidebar-container-children{
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }
}