@import "../variables.scss";

.goal-history-table-container {
    font-size: $smallFontSize;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    .status-headbar {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;

        .status-text {
            font-weight: 700;
            color: $black;
        }

        .tooltip-container {
            cursor: pointer;
        }
    }

    .goal-history-status {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-left: 10px;

        .latest-update-previous {
            background-color: $lightBlackGrey;
            color: $lightBlackBgColor;
            font-weight: 600;
            padding: 0 4px;
        }

        .latest-update-current {
            color: $lightGreenBgColor;
            background-color: $lightGreen;
            font-weight: 600;
            padding: 0 4px;
        }
    }
}

.feedback-goal-modal {
    z-index: 15;
    background-color:  $transparentGrayColor;

    .goal-feedback-container {
        max-width: 440px;
        min-width: 340px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 7px 4px $greyInnerBoxColor;

        .goal-rich-editor {
            width: 100%;
        }

        .feedback-close-icon-container {
            position: absolute;
            top: 4px;
            right: 0;
            z-index: 5;
            cursor: pointer;
            border-radius: 2px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: fit-content;
            padding: 3px;
            transition: background-color 0.2s;
            &:hover {
                background-color: $boxShadowWhiteColor;
            }
        }
    }
}