.tutorials-container-wrapper {
  max-width: 100%;
  font-family: Arial, sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  transform: none;
  transform-origin: 50% 50% 0px;
  padding: 100px;
  padding-bottom: 20px;

  @media(max-width: 991px) {
    padding: 80px 20px;
  }

  .tutorial-header-container {
    width: 100%;
    // max-width: 950px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    text-align: center;

    .tutorial-icon-container {
      text-decoration: none;
      color: #333333;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      border: 1px solid rgb(230, 233, 238);
      padding: 6px 10px;
      border-radius: 100px;
      background-color: rgb(248, 249, 250);
    }

    .tutorial-header {
      color: rgb(12, 12, 12);
      font-size: 28px;
      font-weight: 500;
    }

    .tutorial-header-paragraph {
      word-wrap: break-word;
      text-align: center;
      color: #666666;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .tutorials-container {
    width: 100%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 16px;
    // max-width: 950px;
    display: flex;

    .sidebar-accordian-container {
      border: 1px solid rgb(230, 233, 238);
      border-radius: 14px;
      overflow: hidden;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
  flex-direction: column;

      .tutorial-item-list-accordian {
        height: 100%;
        background-color: rgb(246, 247, 249);
        padding: 20px;
        border: none;
        border-bottom: 1px solid rgb(230, 233, 238);
        border-radius: 0;
        padding: 0;
        transition: all 0.3s ease;

        .accordion-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          padding: 20px 24px;

          cursor: pointer;

          .title-subtitle {
            position: relative;
            display: flex;
            gap: 10px;
            flex-direction: column;

            .accordion-title {
              font-size: 16px;
              color: #262626;
            }

            .accordion-sub-title {
              color: #666666;
              font-weight: 400;
              font-size: 14px;
            }
          }

          .arrow-icon {
            .accordion-dropdown-arrow {
              transform: rotate(0deg);
            }

            .accordion-dropdown-arrow-active {
              transform: rotate(180deg);
            }
          }
        }

        .accordion-content {
          padding: 0;
          transition: max-height 0.5s ease;

          .tutorial-content-wrapper {
            width: 100%;
            padding: 0 20px;

            .tutorials-container {
              width: 100%;

              .sidebar-accordian-container {
                width: 100%;
              }
            }
          }
        }
      }

      .active-tutorial-item-list-accordian {
        background-color: transparent;
      }

      .desktop-view-tutorial-item-list-accordian .accordion-header .arrow-icon {
        .accordion-dropdown-arrow {
          transform: rotate(0deg);
        }

        .accordion-dropdown-arrow-active {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.tutorial-accordian-title {
  display: flex;
  gap: 5px;
  align-items: center;
}

.tutorial-content-paragraph {
  color: #666666;
  padding: 20px;
  font-size: 15px;
}

@media only screen and (min-width: 1200px) {
  .tutorials-container-wrapper .tutorials-container {
    border: 1px solid rgb(230, 233, 238);
    border-radius: 16px;

    .sidebar-accordian-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      min-width: 35%;
      width: 35%;
      border-top: none;
      border-bottom: none;
      border-left: none;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .tutorials-container-wrapper .tutorials-container {
    .sidebar-accordian-container {
      .tutorial-item-list-accordian {
        .accordion-header {
          .arrow-icon {
            .accordion-dropdown-arrow {
              transform: rotate(0deg);
            }

            .accordion-dropdown-arrow-active {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .tutorial-selected-video-thumbnail-container {
      display: none;
    }
  }
}