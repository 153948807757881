@import "../variables.scss";
.scheduling-page-filter-container {
  width: fit-content;
  height: fit-content;
  background-color: $Gray35;
  border-radius: 7px;
  border: 1px solid $Gray29;
  display: flex;
  align-items: center;
  .sort-by-date-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 6px 14px;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: 0.5s;
    .sort-by-date-text{
      font-size: $largerFontSize;
      user-select: none;
    }
  }
  .sort-by-date-wrapper-active{
    background-color: $accent-color-6;
    border-color: $lightBlue;
  }
  .checkbox-content{
    padding: 4px 8px;
  }
}
.filter-right-border-style{
  border-right: 1px solid $Gray29;
}