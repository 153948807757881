@font-face {
  font-family: "Nunito";
  src: url("../styles/fonts/Nunito-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Source Sans 3";
  src: url("../styles/fonts/SourceSans3-VariableFont_wght.ttf");
}
$box-shadow-light-dark:rgba(113, 110, 110, 0.17);
$primaryFontFamily: "Source Sans 3";
$secondaryFontFamily: "Nunito";
$primaryBgColor: #161616;
$secondaryBgColor: #1c1c1c;
$tertiaryBgColor: #313131;
$primaryColor: white;
$secondaryColor: #C2C4C7;
$primaryBg: #fff;
$red: red;
$blue: rgb(14, 114, 177);
$darkBlue:rgba(4, 170, 227, 0.250);
$lightBlue: #2196f3;
$lightBluePrimary: rgba(144, 193, 224, 0.5);
$lightBlueSecondary: rgba(144, 193, 224, 0.25);
$lightBlack: rgba(25, 25, 25, 0.849);
$lightGreyPrimary: rgb(172, 163, 163);
$lightGreySecondary: rgba(203, 200, 200, 0.854);
$gray: gray;
$primaryTextColor: #fff;
$gray: rgb(128, 128, 128);
$fadedGrayPrimary: rgba(128, 128, 128, 0.5);
$fadedGraySecondary: rgba(128, 128, 128, 0.85);
$primaryBorderColor: #1565c0;
$sencondaryBg: #1565c0;
$primaryBorderColor: #1565c0;
$primaryBgColorOnHover: rgb(79, 77, 77);
$sencondaryBorderColor: rgba(0, 0, 0, 0.1);
$secondaryBorderColorHighlighted: rgba(0, 0, 0, 0.7);
$bacgroundBlackColor: #000;
$fadedBlackColor: rgba(66, 66, 66, 0.4);
$extraSmallFontSize: 12px;
$smallFontSize: 14px;
$mediumFontSize: 16px;
$largerFontSize: 18px;
$titleFontSize: 20px;
$drawerFontSize: 20px;
$textFontSize: 24px;
$mediumLargFontSize: 25px;
$subHeaderFontSize: 25px;
$headerFontSize: 40px;
$extraLargFontsize: 40px;
$doubleXlFontSize: 60px;
$bottomBorderColor: rgb(129, 128, 128);
$boxShadowColor: rgb(162, 161, 161, 0.5);
$textColorBlack: #000;
$menuBarFontSize: 34px;
$boxShadowColor: rgb(145, 143, 143);
$primaryBoxShadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$redBorderColor: rgba(239, 29, 29, 0.479);
$lightOrangeColor: rgb(202, 110, 67);
$orangeColor: rgb(136, 83, 60);
$fadedOrangeColor: rgba(136, 83, 60, 0.4);
$darkOrangeColor: rgb(61, 34, 22);
$green: green;
$orange: #ff9800;
$loaderColors: #fff, #fff, #fff, #fff, #fff;
$premiumColor: rgb(255, 215, 0);
$fadedPremiumColor: rgba(255, 215, 0, 0.5);
$bgBlackColor: rgba(0, 0, 0, 0.5);

$screenXs: 480px;
$screenSm: 576px;
$screenMd: 768px;
$screenLg: 992px;
$screenXl: 1200px;

$sencondaryTextColor: rgb(185, 185, 185);
$Gray29: rgb(74, 74, 74);
$Gray35: rgb(35, 35, 35);
$Gray43: rgb(43, 43, 43);
$Gray34: #3d3b3bde;
$graniteGray: #666666;
$graniteGrayFaded: #66666668;
$subHeaderFontSize: 25px;
$headerFontSize: 40px;

$primary-color: #ffffff;
$background-color: #f4f4f4;
$text-color: #333333;

$accent-color-1: #3498db;
$accent-color-2: #e74c3c;
$accent-color-3: #27ae60;
$accent-color-4: #f39c12;
$accent-color-5: #ce3434;
$accent-color-6: #002946;
$accent-color-7: #13505a;
$accent-color-8: #49eaf3;
$accent-color-9: rgb(0, 122, 255);
$accent-color-10: rgb(0, 104, 217);

$accent-color-8-faded: #ccf9f7;
$accent-color-7-faded: rgba(19, 80, 90, 0.3);
$accent-color-3-faded: rgba(39, 174, 95, 0.2);
$accent-color-5-faded: rgba(206, 52, 52, 0.2);

$link-color: #3498db;
$link-hover-color: #2980b9;

$button-bg: #3498db;
$button-text: #ffffff;
$button-hover-bg: #2980b9;

$input-bg: #ffffff;
$input-text: #333333;
$input-border: #555555;

$videoHutBorderColor: rgba(255, 255, 255, 0.3);
$radialGradient: --token-31d2d8ec-6073-495b-98cd-5e142f56bc63,
  rgba(255, 255, 255, 0.15) 2.210024196147939%, hsla(0, 0%, 100%, 0);
$boxShadowWhiteColor: rgb(224, 224, 224);
$borderSecondaryColor: rgb(164, 164, 164);
$boxShawdowSecondaryColor:rgb(0, 0, 0);
$box-shadow-inset: inset 0 0 0 0px rgb(57, 57, 57),
  inset 0 0 0 1px rgb(36, 36, 36);
  $boxShadowSecondaryColor:rgba(113, 110, 110, 0.17);
$bgColorSecondary: rgba(132, 131, 131, 0.15);
$screenXs: 480px;
$screenSm: 576px;
$screenMd: 768px;
$screenLg: 992px;
$screenXl: 1080px;
$black: black;
$darkGrey: rgb(33, 32, 32);
$textWhite: #f6f6f6;
$textLightGrey:#b0b0b0;
$textGrey: #808080;
$borderGrey: rgb(78, 77, 77);
$boxShadowGrey: #808080a8;

$code-editory-bg: #1d1e22;

$success-200: #a5d6a7;
$success-500: #4caf50;
$success-800: #2e7d32;
$placeHolderColor: rgb(117, 117, 117);

$primaryTextColor: #fff;
$fadedGrayWhite: rgba(255, 255, 255, 0.1);
$primaryModalBgColor: rgba(9, 30, 66, 0.24);
$greyContainerColor: rgb(28, 28, 28);
$greyInnerBoxColor: rgb(44, 44, 44);
$installmentPagePrimaryFont: rgb(246, 246, 246);
$installmentPageSecondaryFont: rgb(133, 133, 133);
$installmentPageButton: rgb(246, 246, 246);
$glowingGreen: #00ff00;
$glowingRed: #ee4a13;
$glowingYellow: #ffff10;

$pending: #efc952;

$gray1: rgba(128, 128, 128, 0.180);
$gray2: rgba(128, 128, 128, 0.348);
$matteBlack:  rgb(49, 49, 49);
$matteWhite: rgb(194, 196, 199);
$lightBlackGrey:#091E420F;
$lightBlackBgColor:#44546F;
$lightGreen:#216E4E;
$lightGreenBgColor:#DCFFF1;
$transparentGrayColor:#44546f60;