@import "../variables.scss";

.user-payment-card {
  width: 40%;
  min-width: 320px;
  border: 1px solid $gray;
  padding: 25px 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px $gray;

  .status-tag {
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;

    &.approved {
      background-color: $green;
      color: $primaryColor;
    }

    &.rejected {
      background-color: $red;
      color: $primaryColor;
    }

    &.pending {
      background-color: $orange;
      color: $primaryColor;
    }
  }

  .image-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $sencondaryBorderColor;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview-image {
      max-width: 80%;
      max-height: 80%;
      border: 1px solid $primaryColor;
      border-radius: 4px;
    }
  }

  .payment-card-text {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 800;
  }

  .fee-slip-image {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;

    .approve-button,
    .reject-button {
      padding: 8px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    .approve-button {
      background-color: $green;
      color: $primaryColor;
      padding: 15px 15px;
    }

    .reject-button {
      background-color: $red;
      color: $primaryColor;
      padding: 15px 20px;
    }
  }

  &.approved {
    border-color: $green;
  }

  &.rejected {
    border-color: $red;
  }

  &.pending {
    border-color: $orange;
  }
}
