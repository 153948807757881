@import "../variables.scss";

.card-main-container {
  width: 450px;
  color: $primaryBg;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  font-family: $secondaryFontFamily;
  border: 1px solid rgba(128, 128, 128, 0.5);
  transition: 0.5s;
  font-family: Nunito;
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 0.08) 2.21002%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 20px;

  &:hover {
    background: linear-gradient(
      142deg,
      rgba(255, 255, 255, 0.21) 34.6847%,
      rgba(255, 255, 255, 0) 57.165%
    );
    cursor: pointer;
  }
  &:hover > .image-div > .img {
    transform: scale(120%);
  }

  &.videos-card{
    width: 420px;
    .card-image-container{
      height: 220px;
    }
  }

  .card-image-container {
    width: 100%;
    height: 250px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;

    .card-image {
      width: 100%;
      border-radius: 15px;
      transition: transform 1s;
    }
    
    .overlay-div{
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .card-tags-wrapper {
      position: absolute;
      top: 10px;
      display: flex;
      gap: 5px;
      z-index: 1;
      &:hover > .hidden-tags {
        opacity: 1;
      }
      .card-tag {
        border: 1px solid $primaryBg;
        border-radius: 25px;
        background-color: $lightBlack;
        backdrop-filter: blur(5px);
        font-size: $smallFontSize;
        text-align: center;
        padding: 2px 12px;
        font-weight: 700;
      }
      .hidden-tags {
        opacity: 0;
        transition: 0.2s;
      }

      .html-tag {
        background-color: rgba(227, 85, 53, 0.211);
        border-color: rgb(227, 85, 53);
        color: rgb(227, 85, 53);
      }
      .css-tag {
        background-color: rgba(0, 30, 255, 0.1);
        border-color: rgb(0, 30, 255);
        color: rgb(0, 30, 255);
      }
      .js-tag {
        background-color: rgba(220, 188, 52, 0.2);
        border-color: rgb(220, 188, 52);
        color: rgb(252, 206, 1);
      }
      .react-tag {
        background-color: rgba(0, 145, 255, 0.182);
        border-color: rgb(0, 145, 255);
        color: rgb(0, 145, 255);
      }
    }

    .left{
      left: 15px;
    }

    .right{
      right: 15px;
    }
    .center{
      transform: translateX(0%)
    }
  }

  .card-text-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .card-title{
      font-size: $titleFontSize;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      height: 60px;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
    }
    .video-description-data{
      font-size: $mediumFontSize;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      height: 70px;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
    }
    .videodata-wrapper{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: $mediumFontSize;
      gap:5px;
      font-size: $smallFontSize;
      font-weight: 700;
      margin-top: 5px;
    }
  }
}

@media (max-width: $screenSm) {
  .card-main-container {
    width: 340px;
    height: 225px;
    &.videos-card{
      height: 394px;
      width: auto;
    }
    .card-text-wrapper {
      .card-title{
        font-size: $largerFontSize;
        height: 50px;
      }
    }
  }
}

@media (max-width: 350px) and (max-width: 0px) {
  .card-main-container {
    width: 225px;
  }
}
