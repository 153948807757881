@import "../../components/variables.scss";
@mixin scrollbar {
  &::-webkit-scrollbar {
    background-color: $fadedBlackColor;
    width: 10px;
    border-radius: 8px;
    opacity: 1;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: $screenMd) {
      width: 8px;
    }
  }
  &::-webkit-scrollbar-thumb {
    background: $fadedGraySecondary;
    border-radius: 8px;
    &:hover {
      cursor: grab;
    }
  }
}

.main-daypage-container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  background-color: $primaryBgColor;
  color: $primaryColor;
  align-items: center;
  gap: 20px;
  font-family: $secondaryFontFamily;

  .main-title-div {
    font-size: $headerFontSize;
    color: $primaryColor;
    font-weight: 700;
    padding: 10px 20px;
  }
  .content-navigation {
    display: flex;
    gap: 20px;
    font-size: $largerFontSize;
    width: 100%;
    align-items: center;
    justify-content: center;
    .navigator {
      color: $link-color;
      border-bottom: 2px solid transparent;
      transition: 0.3s;

      &:hover {
        border-color: $link-hover-color;
        cursor: pointer;
      }
    }
  }
  .content-wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    @media (max-width: $screenLg) {
      flex-direction: column;
      gap: 50px;
    }

    .videos-notes-wrapper {
      display: flex;
      flex-direction: column;
      width: 65%;
      transition: 0.3s;
      gap: 50px;
      @media (max-width: $screenLg) {
        width: 100%;
      }
      .videos-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media (max-width: $screenLg) {
          width: 100%;
        }
        .show-scrollbar {
          @include scrollbar;
        }
        .videos-content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          height: 900px;
          overflow: auto;
          padding-right: 20px;
          &::-webkit-scrollbar {
            opacity: 0;
          }
          @media (max-width: $screenLg) {
            height: 650px;
          }
          @media (max-width: $screenMd) {
            height: 450px;
          }
          .video-content-wrapper {
            display: flex;
            flex-direction: column;
            background-color: $fadedBlackColor;
            border-radius: 20px;
            align-items: center;
            padding: 2px;
            .video-content {
              width: 100%;
              cursor: pointer;
              aspect-ratio: 16/9;
              .video-iframe {
                width: 100%;
                height: 100%;
                border-radius: 20px 20px 0px 0px;
              }
            }
          }
        }
      }

      .notes-wrapper {
        max-width: 100vw;
        .note-content-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          padding-top: 20px;
          @media (max-width: $screenLg) {
            justify-content: center;
          }
          .note-content-container {
            width: 380px;
            display: flex;
            flex-direction: column;
            background-color: $fadedBlackColor;
            border: 1px solid $videoHutBorderColor;
            border-radius: 20px;
            @media (max-width: $screenLg) {
              width: 350px;
            }
            .note-content {
              width: 100%;
              cursor: pointer;
              aspect-ratio: 1/1;
              .note-iframe {
                width: 100%;
                height: 100%;
                border-radius: 20px 20px 0px 0px;
              }
            }
          }
        }
      }
    }
    .expanded-videos-wrapper {
      width: 75%;
      @media (max-width: $screenLg) {
        width: 100%;
      }
    }
    .content-separator {
      width: 1%;
      .line {
        position: relative;
        width: 1px;
        height: 100%;
        background-color: $gray;

        .icon {
          position: absolute;
          font-size: $largerFontSize;
          top: 40px;
          right: -15px;
          cursor: pointer;
          background-color: $primaryColor;
          text-align: center;
          color: $primaryBgColor;
          border-radius: 20px;
          height: 30px;
          width: 30px;
          transition: 0.3s;
          font-weight: 800;
          transform: rotate(90deg);
        }

        .rotate-icon {
          transform: rotate(270deg);
          transition: 0.5s;
          font-size: $subHeaderFontSize;
          height: 40px;
          width: 40px;
          border-radius: 30px;
          right: -20px;
        }
      }
      @media (max-width: $screenLg) {
        display: none;
      }
    }
    .question-wrapper {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      transition: 0.3s;
      @media (max-width: $screenLg) {
        width: 100%;
      }
      .questions-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .question-content-wrapper {
          .question-content {
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .hide-question-bar {
      width: 20%;
      transition: 0.3s;
      @media (max-width: $screenMd) {
        width: 100%;
      }
    }
  }
}
.content-header {
  font-size: $subHeaderFontSize;
  color: $link-color;
  font-weight: 700;
  border-bottom: 2px solid transparent;
  width: fit-content;
  transition: 0.4s;
  &:hover {
    cursor: pointer;
    color: $link-hover-color;
    border-color: $link-hover-color;
  }
}
.content-text-wrapper {
  font-size: $mediumFontSize;
  padding: 10px;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  height: fit-content;
  .content-title {
    font-size: $largerFontSize;
    font-weight: 700;
    @media (max-width: $screenMd) {
      font-size: $mediumFontSize;
    }
  }
  @media (max-width: $screenMd) {
    font-size: $smallFontSize;
  }
}
