.create-question-wrapper-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  gap: 10px;
  ::-webkit-scrollbar {
    display: none;
}
  overflow: auto;
  .add-question-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: right;
    .add-question-button {
      padding: 8px 12px;
      border: none;
    }
  }
}
.question-modal-wrapper {
  padding: 10px;
}
