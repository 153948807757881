@import "../variables.scss";
.wrapper-container {
  max-width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .create-goal-select-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .create-goal-label {
      font-size: $extraSmallFontSize;
      .create-goal-label-required {
        color:$red;
        background:transparent;
      }
    }
    .create-goal-select {
      appearance: none;
      outline: none;
      border: none;
      border-radius: 6px;
      font-size: $smallFontSize;
      font-family: Inter, sans-serif;
      letter-spacing: 0em;
      line-height: 24px;
      text-align: left;
      font-weight: 400;
      background: $greyContainerColor;
      color: $installmentPageButton;
      box-shadow: $box-shadow-inset;
      .select {
        padding: 5px;
        .select-box {
          .options-container {
            z-index: 1;
            .list-child {
              background-color: $primaryBgColor;
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
  .user-goal-response-form{
    display: flex;
    gap: 5px;
    align-items: baseline;
    height: 40px;
    .user-goal-response-labels-input{
      width: 160px;
      height: 100%;
      margin-top: 5px;
      .input-component-container{
        height: 100%;
        border: none;
        .input-container{
          height: 100%;
          border: 1px solid $gray;
          &:focus{
            border-color: transparent;
          }
        }
      }
    }
    .user-goal-response-labels-button{
      width: 40px;
      height: 70%;
      padding: 0px;
    }
  }
  .user-goal-response-labels-list{
    min-width: 200px;
    width: 100%;
    max-width: 500px;
    display: flex;
    gap: 6px;
    margin-top: 10px;
    flex-wrap: wrap;
    .user-goal-response-label-wrapper{
      display: flex;
      gap: 3px;
      border-radius: 3px;
      padding: 2px 5px;
      background-color: $Gray43;
      font-size: $extraSmallFontSize;
      color: $primaryColor;
      .user-goal-response-label{
        font-size: $extraSmallFontSize;
      }
      .user-goal-response-label-cancel-button{
        border-radius: 50%;
        text-align: center;
        padding: 0px 6px 2px 6px;
        background-color: $Gray34;
        transition: background-color 0.5s;
        cursor: pointer;
        &:hover{
          background-color: $red;
        }
      }
    }
  }
}
