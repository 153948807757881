@import "../../components/variables.scss";

.leaderboardContainer {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-height: 90vh;

}

.leaderboardTitle {
  font-size: 2rem;
  margin-bottom: 20px;
}



.table-body {
  height: 100%; 
}

.leaderboard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboardTable {
  width: 70%; 
  border-collapse: collapse;
  box-shadow: 0 0 10px 5px $primaryBgColorOnHover;
}

.leaderboardTable th,
.leaderboardTable td {
  padding: 15px;
  border: 1px solid $fadedGrayPrimary;
  text-align: left;
  position: relative;
}

.star {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.leaderboardTable th {
  background-color: $sencondaryTextColor;
  font-weight: 700;
  text-transform: uppercase;
  color: $textColorBlack;
  text-align: center;
}

.leaderboardTable td {
  background-color: $bgBlackColor;
  color: $primaryColor;
  text-align: center;
}

.leaderboardTable tr:nth-child(even) td {
  background-color: $secondaryBgColor;
}

.userProfile {
  display: flex;
  align-items: center;
  padding: 9px;
  background-color: $bgBlackColor;
  border: 1px solid $fadedGrayPrimary;
  color: $primaryColor;
}

.leaderboardTable tr:nth-child(even) .userProfile {
  background-color: $secondaryBgColor;
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: $primaryTextColor;
}
