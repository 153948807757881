.topics-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  background-color: #fff;
  gap: 40px;
  padding: 100px;
  padding-bottom: 20px;

  @media(max-width: 991px) {
    padding: 80px 20px;
  }

  .topics-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;

    .topics-icon {
      color: #333333;
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 14px;
      border: 1px solid rgb(230, 233, 238);
      padding: 6px 10px;
      border-radius: 100px;
      background-color: rgb(248, 249, 250);
      width: fit-content;
    }

    .topics-main-header {
      color: rgb(12, 12, 12);
      font-size: 36px;
      font-weight: 500;
      margin: 0;
      line-height: 110%;
    }

    .topics-header-paragraph {
      word-wrap: break-word;
      color: #606060;
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }
  }

  .topics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    width: 100%;

    .topic-card {
      border: 1px solid rgb(230, 233, 238);
      background-color: rgb(248, 249, 250);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      padding: 16px;
      text-align: center;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }

      .topic-logo {
        height: 25px;
        width: 25px;
        background-color: rgb(219, 255, 153);
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        .topic-logo-img {

          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .topic-name {
        color: #333333;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .topics-section {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    .topics-header {
      align-items: center;
      max-width: 650px;
      width: 100%;

      .topics-main-header {
        text-align: center;
      }

      .topics-header-paragraph {
        text-align: center;
      }
    }

    .topics-grid {
      align-items: center;
      max-width: 650px;
      justify-content: center;
      width: 100%;
    }
  }
}