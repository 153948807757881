.installment-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h4 {
      font-size: 16px;
      margin-bottom: 8px;
    }
  
    p {
      font-size: 14px;
      margin-bottom: 6px;
    }
  }