.integration-section {
  max-width: 100%;
  padding: 15px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px;
  padding-bottom: 20px;

  @media(max-width: 991px) {
    padding: 80px 20px;
  }

  .integration-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    text-align: center;

    .integration-icon {
      color: #333333;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      border: 1px solid rgb(230, 233, 238);
      padding: 6px 10px;
      border-radius: 100px;
      background-color: rgb(248, 249, 250);
    }

    .integration-main-header {
      font-size: 36px;
      color: #0c0c0c;
      font-weight: 500;
      margin: 0;
    }

    .integration-header-paragraph {
      word-wrap: break-word;
      color: #666666;
      font-weight: 400;
      font-size: 16px;
      max-width: 600px;
      margin: 0;
    }
  }

  .integration-grid {
    display: grid;
    gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .integration-card {
      position: relative;
      border-radius: 12px;
      border: 1px solid rgb(230, 233, 238);
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
        background-color: rgb(248, 249, 250);
      }

      .integration-logo-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        height: 48px;
        width: 100%;

        .integration-logo {
          height: 54px;
          width: 54px;
          display: flex;
          align-items: center;
          margin: 0;
          border: 1px solid rgb(237, 239, 243);
          border-radius: 12px;
          background-color: #dbff99;
          padding: 15px;
          flex-shrink: 0;
          .integration-logo-img{
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .integration-name {
          color: #262626;
          height: 100%;
          font-size: 20px;
          display: flex;
          font-weight: 500;
          align-items: center;
          margin: 0;
        }


      }
      .integration-card-glassmorphism {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(3px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        .demoWeekInfos-glassmorphism-text {
          font-size: 20px;
          font-weight: 500;
          color: #fff;  
          margin-bottom: 0;     
        }

      }

      .integration-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .integration-description {
          font-size: 14px;
          color: #666666;
          font-weight: 300;
          margin: 0;
          text-align: left;
          word-wrap: break-word;
        }
      }

      .integration-link {
        text-decoration: none;

        justify-content: left;
        width: 100%;

        .integration-link-container {
          display: flex;
          flex-direction: row;
          gap: 8px;
          justify-content: center;
          color: #262626;
          align-items: center;
          border: 1px solid rgb(237, 239, 243);
          font-weight: 500;
          padding: 5px 16px;
          cursor: pointer;
          border-radius: 100px;
          width: fit-content;
          transition: all 0.3s;

          &:hover {
            transform: scale(0.9);
            border-color: rgb(179, 179, 179);

            .integrations-visit-icon {
              border-color: rgb(179, 179, 179);
            }
          }

          //   .integrations-visit-website {
          //   }
          .integrations-visit-icon {
            padding: 8px;
            border-radius: 50%;
            border: 1px solid rgb(237, 239, 243);
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}