
.headNavBar-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding: 0  20px 0 20px;
  position: fixed;
  z-index: 10;
  .webmaster-logo{
    width: 150px;
    height: 100px;
    position: relative;
    .webmaster-logo-container{
        transform: scale(1.3s);
        position: absolute;
        top: 32px;
    }
}
  .headNavBar-box {
    background-color: #474747c3;
    border-radius: 15px;
    width:825px;
    padding:9px 0px 9px 20px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    backdrop-filter: blur(5px);
    height: 60px;
    
    .nav-buttons-container{
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        overflow-y: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        .nav-buttons{
             margin-right: 20px;
             color: white;
             font-size: 16px;
            transition: all 0.5s ease-in-out;
                cursor: pointer;
                text-shadow: 0px 2px 1px #262626;
        }
        .active-nav{
            color: #ABFF1C;
        }
        
    }
    .login-and-apply-button{
        
        .login-button{
            background-color:  #ABFF1C;
            padding: 12px 18px 12px 18px;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.5s, ease-in-out;
            margin-right: 20px;
    }
    .Apply-now-button-inside-nav{
        display: none;

    }
}
  }
  
}


@media (max-width: 1200px) {
    .headNavBar-container{
        .webmaster-logo{
            width: 100px;
            height: 100px;
            background-color: #ffffff38;
            border-radius: 15px;
        }
        .headNavBar-box{
            width:720px;
            padding:5px;
        }
    }
  }

@media (max-width: 992px) {
    .headNavBar-container{
        .webmaster-logo{
            width: 100px;
            height: 100px;
            background-color: #ffffff5a;
            border-radius: 15px;
        }
        .headNavBar-box{
            width:500px;
            padding:5px;
            .nav-buttons-container{
                margin-left: 10px;
                .nav-buttons{
                     margin-right: 10px;
                     font-size: 14px;
                }

                

            }
            .login-button{
                padding: 5px 16px 5px 16px;
            }
        }

    }
  }
  
  @media (max-width: 768px) {
    .headNavBar-container{
        .webmaster-logo{
            width: 100px;
            height: 100px;
            background-color: #ffffff38;
            border-radius: 15px;
        }
        .headNavBar-box{
            width:500px;
            padding:5px;
            display: flex;
            flex-direction: column ;
            align-items: center;
            justify-content:space-between;
            .nav-buttons-container{
                margin-left: 10px;
                .nav-buttons{
                     margin-right: 10px;
                     font-size: 14px;
                }
            }
            
            .login-and-apply-button{
                 width : 200px ;
                 margin-top: 5px;
                 display: flex;
                 align-items: center;
                 justify-content: space-between;

                .login-button{
                    padding: 5px 16px 5px 16px;
                }
                .Apply-now-button-inside-nav{
                    display: flex;
            
                }
            }
        }
        .Apply-now-button{
            display:none;
        } 
    }

  }
  
  @media (max-width: 576px) {
    .headNavBar-container{
        padding: 0  10px 0 10px;
        .webmaster-logo{
            width: 100px;
            height: 100px;
        }
        .headNavBar-box {
            padding:5px 10px 5px 10px;
            width:320px;
        }
            
        }
    }

  
  @media (max-width: 480px) {
    .headNavBar-container{
        padding: 0  10px 0 10px;
        flex-direction: column;
        justify-content: center;
        .webmaster-logo{
            width: 80px;
            height: 80px;
        }
        .headNavBar-box {
            padding:5px 10px 5px 10px;
            width:380px;
            margin-top: 1px;
        }
            
        }

  }