@import "../../../components/variables.scss";
.user-response-page-main-container{
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .user-response-page-sub-container{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
    }
}
.user-response-goal-and-user-data-wrapper{
    width: 100%;
    border: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
.user-response-page-status-history-container{
    background-color: $primaryColor;
    color: $primaryBgColor;
    border-radius: 5px;
    padding: 10px;
    .user-response-card-data-content{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
.user-goal-response-button-wrapper{
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: center;
}
.goal-status-button {
    min-width: 100px;
    height: 30px;
    border: none;
    box-shadow: $boxShadowSecondaryColor 0px 0.301094px 0.301094px 1px;
    color: $primaryColor;
    padding: 6px 8px;
    background-color: $bgColorSecondary;
    font-size: $smallFontSize;
    transition: all 1s;
    &.reject-button {
      &:hover {
        background-color: $red;
      }
    }
    &.approve-button {
      &:hover {
        background-color: $green;
      }
    }
}
.content-separator{
    width: 100%;
    border-bottom: 1px solid $primaryColor;
    height: 2px;
}

.user-response-card-data-container{
    width: 450px;
    max-width: 450px;
    min-width: 450px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px $gray;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $secondaryBgColor;
    @media (max-width: $screenXs){
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
    .user-response-card-title{
        font-size: $largerFontSize;
        font-weight: 700;
        border-bottom: 1px solid $gray;
        padding-bottom: 3px;
    }
    .user-response-card-data-content-container{
        width: 100%;
        padding-top: 10px;
        max-height: 500px;
        height: fit-content;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &::-webkit-scrollbar{
            display: none;
        }
    }
}
