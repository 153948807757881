@import "../variables.scss";
.payment-card {
    border: 1px solid $videoHutBorderColor;
    padding: 15px;
    border-radius: 8px;
    background-color: $secondaryBgColor;
  
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .info {
      font-size: 16px;
      margin-bottom: 8px;
      font-weight: 900;
    }
  }