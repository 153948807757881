@import "../variables.scss";
.rich-text-editor-toolbar {
    height: fit-content;
    padding: 5px 8px;
    display: flex;
    gap: 5px;
    &.hidden{
      display: none;
    }
    .rdw-option-wrapper {
      border: none;
      &:hover{
        box-shadow: none;
        background-color: #89CFF0;
      }
    }
    .rdw-option-active{
      box-shadow: none;
      background-color: #89CFF0;
    }
    .rdw-dropdown-wrapper{
      border: none;
      border: 1px solid rgba(236, 236, 236, 0.7);
      &:hover{
        box-shadow: none;
      }
    }
  }
  
  .rich-text-editor-wrapper {
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    height: fit-content;
    width: 100%;
    background-color: #fff;
  }
  .rich-text-editor-container {
    padding: 16px;
    height: 187px !important;
    overflow: scroll !important;
    font-weight: 400;
    font-size: 16px;
    color: #242424;
    line-height: 24px;
  }
  .rdw-dropdown-optionwrapper{
    color: black;
    border: 1px solid rgb(137, 136, 136);
    border-top: none;
    }
    .rdw-image-modal{
      background-color: #f2f0f0;
      color: #000;
      border: #242424;
    }
    .rdw-embedded-modal{
       background-color: #f2f0f0;
      color: #000;
      border: #242424;
      height: fit-content;
    }