.chat-input-wrapper-continer {
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
    height: fit-content;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    .chat-input-container {
        font-size: 16px;
        color: white;
        line-height: 24px;
        max-height: 200px;
        overflow-x: scroll;
        &:focus {
         outline: none;
        }
    }
    .chat-footer-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .chat-options-container{
            display: flex;
            align-items: center;
            .chat-options-container-item {
                cursor: pointer;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                &:hover {
                    background-color: gray;
    
                }
                &:active{
                    background-color: #ffffff4a;
                }
            }
        }  
        .chat-message-send-btn {
            padding: 2px;
            border-radius: 50%;
            display: flex;
            align-self: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: gray;

            }
            &:active{
                background-color: #ffffff4a;
            }
        }
        .chat-message-send-btn-disabled  {
            cursor: default;
            &:active{
                background-color: gray;
            }
        }
    }
    .chat-input-bold-text {
        font-weight: 900;
    }
    .chat-input-italic-text {
        font-style: italic;
    }
    .chat-input-underline-text {
        text-decoration: underline;
    }
    .chat-input-files-container {
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        .chat-input-file-sub-container {
            position: relative;
            .chat-input-file {
                height: 40px;
                width: 40px;
            }
            .chat-file-cross-btn {
                position: absolute;
                height: 20px;
                width: 20px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                border: 1px solid #fff;
                color: crimson;
                background-color: #fff;
                font-weight: 900;
                top: 0%;
                left: 100%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                &:hover{
                    background-color: crimson;
                    color: #fff;
                }
            }
        }
    }
}
.chat-input-tag-wrapper {
    height: 1px;
    width: 1px;
}