@import "../../components/variables.scss";

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;

  @media screen and (max-width: 768px) {
    left: 20%;
  }

  @media screen and (max-width: 480px) {
    left: 20%; 
  }

  .dot {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 13px; 
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 1.5s ease-out infinite;
    }
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($loaderColors, $i);
        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}
@keyframes wave {
  50%, 75% {
    transform: scale(3);
  }
  80%, 100% {
    opacity: 0;
  }
}
