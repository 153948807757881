@import "../../components/variables.scss";
.user-payment-feeplan-and-installment-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .user-payment-feeplan-and-installment-sub-wrapper {
    max-width: $screenLg;
    width: 80%;
    @media (max-width: $screenMd) {
      width: 90%;
    }
    .user-payment-page {
      padding: 20px;
      width: 100%;
      height: 100%;

      min-height: 100%;
      background-color: $primaryBgColor;
      .user-payment-page-header {
        margin-bottom: 20px;
      }
      .fee-plan-card {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        flex-wrap: wrap;
        .fee-plan-card-input-wrapper {
          width: 450px;
          .payment-card-label-focus {
            border-radius: 8px;
            box-shadow: 1px 1px 15px -5px rgb(10 225 46);
            transform: scale(102%);
          }
          .payment-card {
            width: 60vw;
          }
        }
      }
      .button-wrapper {
        margin-top: 20px;
      }

      .button {
        padding: 10px;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #2980b9;
        }

        &:disabled {
          background-color: #bdc3c7;
          cursor: not-allowed;
        }
      }
    }
  }
}
