@import '../../components/variables.scss';

.back-button-container {
  border-radius: 50%;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primaryBg;
}
  