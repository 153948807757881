.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    height: 20px;
    width: 20px;
    border: 5px solid #fff;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #ff4646;
    border-radius: 50%;
    animation: spin-monorepo-loader 1.5s infinite linear;
  }
}

@keyframes spin-monorepo-loader {
  100% {
    transform: rotateZ(360deg);
  }
}
