@import "../variables.scss";

.accordion-container {
  width: 100%;
  border-radius: 7px;
  border: 1px solid $videoHutBorderColor;
  position: relative;
  .accordion-header {
    font-size: $subHeaderFontSize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
    cursor: pointer;

    .title-subtitle {
      .accordion-title {
        font-size: $mediumLargFontSize;
        font-weight: 500;
        white-space: wrap;
      }
      .accordion-sub-title {
        font-size: $titleFontSize;
        color: $sencondaryTextColor;
      }
    }

    .arrow-icon {
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .accordion-dropdown-arrow {
        transform: rotate(0deg);
        transition: all .3s ease-in-out;
      }

      .image {
        width: 20px;
        height: 20px;
      }

      .accordion-dropdown-arrow-active {
        transform: rotate(180deg);
      }

      .position-right {
        right: 0px;
      }

      .position-left {
        left: 30px;
      }

      .position-center {
        left: 50%;
      }
    }
  }
  .accordian-selected-side-high-lighter{
    flex: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
    z-index: 1;
    background-color: var(--token-c3cf5b6a-8891-4d0f-86d5-3f57794982df, rgb(183, 255, 51));
    opacity: 1;
    will-change: auto;
  }
  .accordion-content {
    color: $primaryColor;
    max-height: 0;
    overflow: hidden;

    .content-wrapper {
      padding: 10px;
    }
  }

  .open-accordion {
    max-height: 100vh;
  }

  .closed-accordion {
    max-height: 0;
  }
}

.disable-accordion {
  opacity: 0.5;

  &:hover {
    cursor: default;
  }
}

@media (max-width: $screenMd) {
  .accordion-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .accordion-header {
      padding: 10px;

      .accordion-title {
        font-size: $largerFontSize;
        font-weight: 800;
        overflow: hidden;
        color: $primary-color;
      }

      .accordion-sub-title {
        font-size: $largerFontSize;
        font-weight: 600;
        padding: 10px;
        overflow: hidden;
        color: $primary-color;
        z-index: 0;
      }
    }
  }
}

//480
@media (max-width: $screenXs) {
  .accordion-container {
    width: 100%;
    .accordion-header {
          padding: 10px 5px 10px 10px;
      .accordion-title {
        font-size: $largerFontSize;
        font-weight: 800;
        overflow: hidden;
      }

      .accordion-sub-title {
        font-size: $largerFontSize;
        font-weight: 600;
        padding: 10px;
        overflow: hidden;
        color: $primary-color;
        z-index: 0;
      }
    }
  }
}
