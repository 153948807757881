@import "../variables.scss";
.tab-main-container{
    color: $graniteGray;
    border-bottom: 1px solid transparent;
    font-weight: 500;
    font-size: $smallFontSize;
    line-height: 1.25;
    text-transform: uppercase;
    max-width: 360px;
    position: relative;
    min-height: 48px;
    padding: 12px 16px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: color 0.4s, border-color 0.2s;
}
.tab-main-container-disabled{
    color: $graniteGrayFaded;
    cursor: default;
}
.selected-tab-container{
    color: $accent-color-8;
    border-color: $accent-color-8;
}