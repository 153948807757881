@import "../variables.scss";
.create-goal-component-wrapper {
  background-color: $darkGrey;
  padding: 25px 30px;
  width: 80%;
  height: 90%;
  @media (max-width: $screenMd) {
    width: 95%;
  }
  display: flex;
  gap: 15px;
  flex-direction: column;
  overflow: auto;
  position: relative;
  color: $primaryColor;
  border-radius: 10px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    overflow: hidden;
    background-color: $Gray34;
  }
  .close-button {
    position: absolute;
    top: 5px;
    right: 4px;
    cursor: pointer;
  }
  .add-goal-button-container {
    .add-goal-button:not([disabled]):hover {
      background-color: $Gray29;
      color: $primaryColor;
    }
    .add-goal-button {
      min-width: 100px;
      border: none;
      color: $primaryBgColor;
    }
  }
}
.create-goal-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .create-goal-label {
    font-size: 12px;
  }
  .create-goal-input {
    width: 100%;
    .input-component-container {
      border: none;
      .input-container {
        appearance: none;
        width: 100%;
        height: auto;
        outline: none;
        border: none;
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 14px;
        font-family: Inter, sans-serif;
        letter-spacing: 0em;
        line-height: 24px;
        text-align: left;
        font-weight: 400;
        background: $greyContainerColor;
        color: $installmentPageButton;
        box-shadow: $box-shadow-inset;
      }
    }
  }
}
.description-text-goal-conatiner {
  appearance: none;
  width: 100%;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-family: Inter, sans-serif;
  letter-spacing: 0em;
  line-height: 24px;
  text-align: left;
  background: $greyContainerColor;
  color: $installmentPageButton;
  box-shadow: $box-shadow-inset;
  resize: vertical;
  border-radius: 10px;
}
