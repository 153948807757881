.social-links-page {
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .social-link-page-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__iframe-container {
      width: 80%;
      height: 80vh;
      overflow: hidden;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  
    &__iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  