@import "../../../components/variables.scss";
.create-goal-wrapper-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;
  .create-goal-header-button-wrapper {
    display: flex;
    justify-content: space-around;
     align-items: center;
     .create-goal-header{
      width: 100%;
      min-width: fit-content;
      display: flex;
      align-items: center;
      justify-content: left;
     }
    .add-goal-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: right;
      align-items: center;
      .add-goal-button {
        padding: 8px 16px;
        border: none;
      }
    }
  }
}
.goal-modal-wrapper {
  padding: 10px;
  background-color: $boxShadowGrey;
}
