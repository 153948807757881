@import "../variables.scss";

.sidebar-user-profile {
    border-radius: 10px;
    width: 250px;
    padding: 20px 10px;
    margin-bottom: 10px;
    background-color: $tertiaryBgColor;



    .sidebar-user-profile-top {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;

        .sidebar-user-social-media {
            border: 1px solid gray;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            margin-bottom: 10px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .sidebar-user-social-media-img {
                height: 100%;
                width: 100%;
                object-fit: contain;

            }

        }

        .sidebar-user-profile-left,
        .sidebar-user-profile-right {
            width: 20px;
        }

        .sidebar-user-profile-center {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 5px;

            .sidebar-user-profile-center-text-h1 {
                font-size: 22px;
                margin-bottom: 0;

                @media (max-width: 525px) {
                    display: none;

                }
            }
        }

    }

    .sidebar-user-profile-bottom {
        margin-top: 10px;

        text-align: center;

        .sidebar-user-profile-bottom-p {
            margin: 0;
            font-size: 14px;
            color: rgb(16, 120, 247);
        }
    }


    @media (max-width: 525px) {
        display: none;

    }

}


.small-sidebar-user-profile-dp {
    margin-right: 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    flex-shrink: 0;

    .small-sidebar-user-profile-img {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .small-sidebar-user-profile-dp-img {
        height: 100%;
        width: 100%;
        object-fit: cover;

    }

    @media (min-width: 524px) {
        display: none;
    }
}






.sidebar-user-profile-center-dp {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    .sidebar-user-profile-center-dp-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}

.sidebar-user-profile-dp {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;

    .sidebar-user-profile-dp-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}