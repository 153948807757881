.tutorial-accordian-content-container {
  background-color: rgb(246, 247, 249);
  border: 1px solid rgb(230, 233, 238);
  border-radius: 20px;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  height: min-content;
  justify-content: flex-start;
  overflow: visible;
  padding: 24px;
  width: 100%;
  .tutorial-accordian-content-container-header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 6px;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%;
    .tutorial-accordian-content-header {
      white-space: pre-wrap;
      width: 100%;
      word-break: break-word;
      word-wrap: break-word;
      font-size: 17px;
      font-weight: 500;
      color: rgb(38, 38, 38);
      margin: 0;
    }
    .tutorial-accordian-content-header-paragraph {
      white-space: pre-wrap;
      width: 100%;
      word-break: break-word;
      word-wrap: break-word;
      font-size: 15px;
      font-weight: 400;
      color: rgb(102, 102, 102);
      margin: 0;
    }
  }
  .video-container {
    position: relative;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid rgb(230, 233, 238);
    .tutorial-accordian-video-thumbnail {
      aspect-ratio: 1.7944444444444445 / 1;
      height: var(auto, 111px);
      width: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
    .tutorial-accordian-video-thumbnail-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      cursor: pointer;
    }
    .tutorial-video-thumbnail-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      transform: none;
      transform-origin: 50% 50% 0px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .tutorial-accordian-content-container {
    border: none;
    border-radius: 0;
    background-color: transparent;
    padding: 40px;
    gap: 30px;
  }
}
