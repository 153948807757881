.dashboard-question-section-container{
    width: 380px;
    max-height: 360px;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    background-color: #2B2F33;
    .question-section-header {
      color: #f9f8fa;
      font-size: 14px;
      font-weight: 600;
    }
}