@import "../../components/variables.scss";
.goal-stats-page-main-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.goal-stats-page-sub-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 80%;
}
.indicators-wrapper{
    width: 100%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.indicator-card{
    display: flex;
    align-items: center;
    background-color: $Gray43;
    border-radius: 10px;
    padding: 3px 6px;
    gap: 5px;
    .goal-indicators{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $primaryColor;
        display: inline-block;
    }
    .goal-over-due{
        background-color: $accent-color-5;
    }
    .goal-completed{
        background-color: $accent-color-3;
    }
    .goal-indicator-label{
        font-size: $extraSmallFontSize;
    }
    .goal-pending{
        background-color: $pending;
    }
}
.goals-tools-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}