@import "../variables.scss";

.verification-page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding-top: 20px;
  .back-arrow {
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: -20px;
    top: -30px;
    left: -20px;
    align-items: center;
    justify-content: center;
    &:hover {
      font-size: $largerFontSize;
    }
    .svg-icon {
      color: white;
      height: 35px;
      width: 35px;
      border-radius: 50%;
    }
  }
  .heading-container {
    color: $primaryColor;
  }
  .input-component {
    height: 75px;
    width: 100%;
    &:disabled {
      opacity: 0.5;
      border: none;
    }
  }
  .button-container-verification {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
    .send-verify-otp {
      height: 50px;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      &:hover{
        background-color: $fadedGrayPrimary;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .resend-otp {
      color: $primaryColor;
      font-size: $smallFontSize;
    }
    .resend-otp-disable {
      cursor:default;
    }
    .resend-otp-enable {
      cursor: pointer;
      &:hover{
        color: $lightBlue;
      }
    }
  }
  .error-container {
    color: $red;
    font-size: $smallFontSize;
    padding-top: 5px;
  }
}
