@import "../variables.scss";
.user-response-card-data-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: space-between;
  padding: 3px 5px;
  .user-response-card-data-content {
    width: 100%;
    padding: 1px;
    word-wrap: break-word;
  }
  .user-response-card-content-title {
    color: $gray;
  }
}
