.discussion-sidebar-container {
    height: 100%;
    width: fit-content;
    padding: 16px;
    min-width: 25%;
    border: 1px solid rgba(256, 256, 256, 0.2);
    .discussion-sidebar-sub-container{
        .discussion-sidebar-options-container {
            padding: 16px;
            .discussion-sidebar-option {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 8px;
                border-radius: 5px;
                background-color: rgb(180, 180, 180, 0.1);
                cursor: pointer;
                &:hover {
                    background-color: rgb(180, 180, 180, 0.4);
                }
            }
        }
    }
}