.code-block-input-container {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  .accordian-container {
    width: 100%;
    .accordion-header {
      padding-bottom:0px 10px;
    }
    .accordion-content {
      width: 100%;
    }
  }
}
