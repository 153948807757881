@import "../variables.scss";

.create-password-wrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
  .create-password-heading {
    color: $primary-color;
    font-weight: 700;
    font-size: 30px;
    padding-bottom: 10px;
  }
  .input-component {
    height: 75px;
    width: 100%;
  }
  .create-password-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    &:hover {
      background-color: $lightBluePrimary;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .error-container {
    color: $red;
    font-size: $smallFontSize;
    min-height: 12px;
    visibility: hidden;
    padding-top: 5px;
    &.show-error {
      visibility: visible;
    }
  }
  .password-description {
    border: 1px solid $borderGrey;
    padding: 5px;
    border-radius: 10px;
    .password-requirement-header {
      font-weight: 900;
      font-size: $titleFontSize;
      color: $primaryColor;
      padding: 10px;
    }
    .password-requirement {
      font-weight: 600;
      color: $primaryColor;
      font-size: $largerFontSize;
      padding: 10px;
    }
  }
}
