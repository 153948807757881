@import "../variables.scss";
.tooltip-goal-container{
    margin: 0;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px;
    gap: 20px;
}
.tooltip-goal-input{
    width: 100%;
    .input-component-container{
        &:focus{
            border: 1px solid transparent;
        }
        .input-container{
            color: $primaryBgColor;
        }
    }
}
.goals-input-and-input-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 15px;
}
.tooltip-goal-description-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .tooltip-goal-label{
        font-weight: 700;
    }
    .tooltip-goal-description{
        font-size: $smallFontSize;
    }
}
.user-goal-response-container{
    display: flex;
    width: 100%;
    gap: 15px;
    align-items: flex-end;
    @media(max-width:$screenXs){
        flex-direction: column;
        align-items: flex-start;
    }
    .user-response-component-inputs-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3px;
        .input-component-wrapper{
            width: 100%;
            .input-component-container {
                .input-container{
                    color: $primaryBgColor;
                }
    
            }
        }
    }
}
.goal-status-card-main-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}
.goal-current-status-wrapper{
    font-size: $largerFontSize;
    color: $Gray43;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 300ms;
    .view-goal-history-button{
        font-size: $largerFontSize;
        color: $Gray43;
        font-weight: 500;
        cursor: pointer;
        transition: all 200ms;
        &:hover{
        color: $accent-color-1;
        }
    }
    .history-open{
       rotate: 180deg;
    }
}
.goal-current-status{
    font-size: $largerFontSize;
    color: $bgBlackColor;
    font-weight: 600;
    &.goal-active{
        color: $accent-color-3;
    }
    &.goal-container-table-disabled{
        color: $Gray43;
    }
    &.goal-rejected{
        color: $accent-color-5;
    }
    &.goal-completed{
        color: $accent-color-3;
    }
    &.goal-pending{
        color: $pending;
    }
}
.goal-container-table-compeleted {
    background-color: $accent-color-3;
    color: $primaryColor;
}
.user-response-status-completed-wrapper{
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: $largerFontSize;
    color: $textColorBlack;
    justify-content: flex-start;

    .user-response-status-completed-label{
        color: $textColorBlack;
    }
    .user-response-status-completed{
        font-weight: 600;
    }
}
.user-response-input-label{
    font-size: $extraSmallFontSize;
    color: $Gray34;
}

.usergoal-description-text-container {
    column-span: 10;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    min-height: 40px;
}

.usergoal-user-response-input-label-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}