.dsa-question-page-container {
  min-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  background-color: #161616;
  font-family: "Source Sans 3";
  color: #fff;
  .dsa-question-page-sub-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .about-question {
      font-size: 20px;
      margin-top: 5px;
      font-weight: 500;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      .h3-header-style{
        display: flex;
        gap: 30px;
        color: #6d6c6c;
      }
      .question-page-description {
        width: 100%;
        display: flex;
        font-size: 18px;
        padding: 0px 0px;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
      }
    }
  }
  .dsa-question-sub-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
