@import "../variables.scss";
.goals-table-container {
  border: 1px solid $videoHutBorderColor;
  width: 100%;
  min-width: 300px;
  display: flex;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-thumb{
    background-color: $gray;
  }
}

.goals-table-sub-container {
  display: flex;
  overflow-x: scroll;
  border-bottom: 1px solid $videoHutBorderColor;
  &::-webkit-scrollbar {
    display: none;
  }
}

.goals-table-row-container {
  max-height: 60vh;
  min-height: 50vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.goals-table-column-cell{
    display: flex;
    border-bottom: 1px solid $fadedGrayWhite;
    width: fit-content;
}

.goals-table-header-cell {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $fadedGrayWhite;
  border-left: 1px solid $fadedGrayWhite;
  
}
.goals-table-header-cell-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
}

.goals-table-row {
  padding: 10px;
  border-left: 1px solid $fadedGrayWhite;
  min-width: 200px;
}

.goal-container-table {
  background-color: $primaryColor;
  color: $secondaryBgColor;
  padding: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  font-size: $mediumFontSize;

  &:hover {
    background-color: $accent-color-1;
    color: $primaryColor;
  }
  &.goal-pending{
    background-color: $pending;
    color: $secondaryBgColor;
  }
  &.goal-rejected{
    background-color: $accent-color-5;
  }
}
.goal-container-table-compeleted {
  background-color: $accent-color-3;
  color: $primaryColor;
}
.goal-container-table-incompeleted {
  background-color: $accent-color-5;
  color: $primaryColor;
}
.tooltip-goal-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .tooltip-goal-submit-btn {
    padding: 5px;
    border: 1px solid $accent-color-3;
    color: $accent-color-3;
    font-weight: 700;
    border-radius: 5px;
    min-width: 200px;
    &:hover {
      color: $primaryColor;
      background-color: $accent-color-3;
    }
  }
}
.goal-container-table-disabled {
  opacity: 0.5;
}
.goal-status-tooltip-container{
  position: absolute;
  z-index: 1;
  .goal-status-tooltip{
    max-width: 0;
    overflow: hidden;
    background-color: $gray;
    color: $primaryColor;
    text-align: center;
    opacity: 0;
    transform: translate(10px, 5px);
    font-size: $smallFontSize;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid $primaryColor;
      left: 10px;
      top: -10px;
      filter: drop-shadow(0 -1px 0 $gray);
    }
  }
}
.goal-info-and-tooltip-wrapper{
  position: relative;
  &:hover > .goal-status-tooltip-container > .goal-status-tooltip{
    border-radius: 5px;
    overflow:hidden;
    opacity: 1;
    max-width: 200px;
    width: 180px;
    padding: 0px 5px;
  }
}
.goal-status-tooltip-container-upward{
  top: -30px;
}