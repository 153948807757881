@import "../variables.scss";
@mixin common-tags {
    border-radius: 25px;
    background-color: $primaryBgColor;
    font-size: $largerFontSize;
    text-align: center;
    cursor: pointer;
    padding: 2px 15px;
}
.filter-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    .filter-wrapper{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $primaryTextColor;
        padding: 10px 20px;
        border-radius: 20px;
        gap: 10px;
        justify-content:center;
        gap: 5px;


        .filter-tag{
            @include common-tags;
            transition: all 0.5s ease;
            &:hover{
                transition: all 0.5s ease;
                color: $lightOrangeColor;
                border-color: $lightOrangeColor;
                color: $primaryBgColor;
                background-color: $primaryTextColor;
                opacity: 0.8;
            }
            &:hover.active-tag{
                background-color: $primaryTextColor;
                opacity: 1;
            }
            @media (max-width:$screenMd){
                font-size: $mediumFontSize;
            }
        }
        .active-tag{
            font-weight: 500;
            color: $primaryBgColor;
            background-color: $primaryTextColor;
        }
        .clear-filter{
            @include common-tags;
            color: $primaryBgColor;
            background: $primaryTextColor;
            transition: 0.2s;
            &:hover{
                transform: scale(105%);
            }
        }
    }

}
