@import "../../../components/variables.scss";
.all-user-goal-status-container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  .user-goal-status-header {
    width: 100%;
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .goals-list-card-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    gap: 5px;
    padding-left: 24px;
    &::-webkit-scrollbar {
      height: 5px;
    }
    .goals-list-table-head {
      display: flex;
      gap: 5px;
      min-width: fit-content;
      padding: 5px;
      background-color: $bgColorSecondary;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: fit-content;
      .list-head {
        padding: 5px;
        width: 110px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $smallFontSize;
        color: $primaryColor;
        font-family: sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .batch-head {
        width: 120px;
      }
      .title-head,
      .response-head {
        width: 150px;
      }
      .question-type-head{
        width: 150px;
      }
    }
  }
  .goals-list-body {
    display: flex;
    gap: 10px;
    flex-direction: column;
    height: 500px;
    overflow-y: auto;
    width: fit-content;
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
  .goal-select-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left:24px ;
    .goal-label {
      font-size: $extraSmallFontSize;
    }
  }
  .no-data-found-container {
    position: static;
  }
}
