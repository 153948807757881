@import "../variables.scss";
.create-dsa-question-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .issue-title-body-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .issue-title-input-wrapper {
      width: 100%;
      .create-issue-title-input {
        width: 100%;
      }
    }
  }
  .branch-files-container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .files-wrapper {
      .remove-file {
        width: 100%;
        display: flex;
        justify-content: right;
        .file-remove-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          cursor: pointer;
          border-radius: 50%;
          background-color: #fff;
          &:hover {
            background-color: red;
          }
        }
      }
      .add-files-container {
        .add-button {
          cursor: pointer;
        }
      }
    }
  }
}
