.question-input-container {
  width: 100%;
}
.option-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;

  .accordian-container {
    width: 100%;
    .accordion-header {
      padding-bottom: 10px 0px;
    }
    .accordion-content {
      width: 100%;
    }
  }
}
.upload-image-conatiner-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  .upload-image-wrapper {
    background-color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 0;
    .upload-image-label {
      width: 100%;
      .upload-image-text-and-icon {
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        .upload-image-label-text {
          width: fit-content;
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
  }
  .uploaded-mage-conatiner {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    .image-container {
      max-width: 260px;
      max-height: 200px;
    }
    .upload-image-button {
      height: fit-content;
      border: none;
    }
  }
}
