.faq-page {
  max-width: 100%;
  font-family: Arial, sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 100px;
  @media(max-width: 991px) {
    padding: 80px 20px;
  }
  .header-data {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    text-align: center;
    .faq-icon-container {
      color: #333333;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      border: 1px solid rgb(230, 233, 238);
      padding: 6px 10px;
      border-radius: 100px;
      background-color: rgb(248, 249, 250);
    }
    .faq-page-heading {
      font-size: 28px;
      color: rgb(12, 12, 12);
      font-weight: 500;
    }
    .faq-heading-paragraph {
      max-width: 850px;
      word-wrap: break-word;
      text-align: center;
      color: #666666;
      font-weight: 400;
    }
  }
  .faq-list-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
  .faq-item-list-accordian {
    background-color: #f8f9fa;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid rgb(230, 233, 238);
    border-radius: 14px;
    padding: 0;
    height: fit-content;
    .accordion-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      padding: 20px 24px;

      cursor: pointer;
      .title-subtitle {
        position: relative;
        .accordion-title {
          font-size: 16px;
          color: #262626;
        }
      }
      .arrow-icon {
        .accordion-dropdown-arrow {
          transform: rotate(0deg);
        }
        .accordion-dropdown-arrow-active {
          transform: rotate(135deg);
        }
      }
    }
    .accordion-content {
      padding: 0;
      transition: max-height 0.5s ease;
      box-sizing: border-box;
    }
  }
}

.faq-item-list-body {
  color: #666666;
  font-size: 14px;
  width: 100%;
  border-top: 1px solid rgb(230, 233, 238);
  padding: 20px 24px;
}

@media only screen and (min-width: 1200px) {
  .header-data {
    max-width: 950px;
    padding: 0 150px;
  }
  .faq-list-container {
    padding: 0;
  }
  .faq-item-list-accordian{
    max-width: 47%;
    
  }
}

@media (min-width: 810px) and (max-width: 1199px) {
  .header-data {
    max-width: 850px;
    padding: 0 120px;
  }
  .faq-list-container {
    max-width: 950px;
    padding: 0 120px;
  }
}

@media (max-width: 809px) {
  .header-data {
    max-width: 450px;
  }
  .faq-list-container {
    max-width: 450px;
  }
}
