.defaultClass{
    border: none;
    background: linear-gradient(to bottom, #ABFF1C, #e6ffbb);
    color: rgb(39, 39, 39);
    padding: 15px 25px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
}