.open-window-container {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 0px 10px 40px 10px;
  flex-wrap: wrap;
  overflow: scroll;
  flex: 1;
  .code-editor-container{
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    overflow: hidden;
    background-color: #a39c9c;
  }
  .question-input-container {
    width: 100%;
  }
}
