.inputContainer {
    position: relative;

    .defaultInputClass {
        border: none;
        border: 1px solid rgba(128, 128, 128, 0.203);
        box-sizing: border-box;
        border-radius: 10px;
        outline: none;
        padding: 30px 22px 15px 22px;
        background: white;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.87);
        box-shadow: 3px 3px 16px -10px gray;
        width: 100%;

        &::placeholder {
            font-size: 12px;
            opacity: 0.7;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .absolute {
        position: absolute;
    }

    .defaultLabelClass {
        left: 0;
        left: 22px;
        top: 7px;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.6);

    }
}

.inputDropdownContainer {
    .defaultInputDropdownClass {
        position: relative;
        border: none;
        border: 1px solid rgba(128, 128, 128, 0.203);
        box-sizing: border-box;
        border-radius: 10px;
        outline: none;
        padding: 15px 22px 15px 22px;
        background: white;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.87);
        box-shadow: 3px 3px 16px -10px gray;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }

    .defaultLabelClass {
        left: 0;
        left: 22px;
        top: 7px;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;

        color: rgba(0, 0, 0, 0.6);

    }

}

@media only screen and (max-width: 600px) {

    .inputContainer {

        .defaultLabelClass {
            font-size: 4.5vw;
        }
    }

    .inputDropdownContainer {

        .defaultLabelClass {
            font-size: 4.5vw;
        }
    }
}