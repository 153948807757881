@import '../../components/variables.scss';

.notes-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;

  .notes-time {
    font-size: 20px;
    margin-top: 5px;
    font-weight: 700;

    .notes-day{
        margin-left: 30px;
    }
  }

  .notes-error{
    font-size: 20px;
    color: red;
    margin-top: 30px;
  }

  .iframe-container {
    background-color: $fadedGrayPrimary;
    border-radius: 10px;
    margin-top: 20px;

    iframe {
      width: 50vw;
      aspect-ratio: 4/3;
      height: calc(100% - 70px);
      border-radius: 10px 10px 0 0;
    }

    p {
      padding: 7px 18px;
    }
  }
}

@media (max-width: 1500px) {
  .notes-page-container {
    .iframe-container {
      iframe {
        width: 55vw;
      }
    }
  }
}

@media (max-width: 1380px) {
  .notes-page-container {
    .iframe-container {
      iframe {
        width: 72vw;
      }
    }
  }
}

@media (max-width: 1024px) {
  .notes-page-container {
    .iframe-container {
      iframe {
        width: 81vw;
      }
    }
  }
}

@media (max-width: 900px) {
  .notes-page-container {
    .iframe-container {
      iframe {
        width: 75vw;
      }
    }
  }
}

@media (max-width: 650px) {
  .notes-page-container {
    .iframe-container {
      iframe {
        width: 100%;
      }
    }
  }
}
