body {
  background: var(--light-red);
}
.registerPage {
  background: var(--light-red);
  display: flex;
  // height: 100vh;
}

.registerPageWrapperBlack {
  .registerPage {
    background: #000000;
    height: 100%;
    width: 100%;
    padding: 5px;
    color: #fff;
  }
}
.registerPageWrapperWhite {
  .registerPage {
    background: var(--light-red);
    color: #000;
  }
}

// .navbar__white {
//     background: white;
// }
// .navbar__black {
//     background: black;
// }
.registerPageWrapper {
  position: absolute;
  width: 100%;
  z-index: -8;
}

@media only screen and (max-width: 800px) {
  .registerPage {
    flex-direction: column;
    height: fit-content;
  }
}
