@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');
@font-face {
  font-family: "Founders Grotesk";
  src:url("./assets/fonts/founders_grotesk/Test\ Founders\ Grotesk\ Medium\ 500.otf"); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
}

:root {
  --red: #ED303C;
  --light-red: white;
  --orange:#F4634B;
  --shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  --main-font: "Founders Grotesk";
}

.App{
  position: relative;
}








