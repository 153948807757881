.faqComponentBlack {
  background-color: #000;
  color: #fff;
}

.faqComponentWhite {
  background-color: #ffffff;
  color: #000;
}

.faqComponent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 100px;
  padding-bottom: 20px;

  @media(max-width: 991px) {
    padding: 80px 20px;
  }

  .rightSubscriptionCircle {
    position: absolute;
    right: 0;
    top: 100px;
    height: 10vw;
  }

  .leftSubscriptionCircle {
    position: absolute;
    left: 0;
    height: 10vw;
    bottom: 100px;
  }

  .planDescription {
    width: 100%;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;
    }

    p {
      color: #767d86;
      margin-bottom: 40px;
    }
  }

  .dropDownList {
    width: 100%;
    margin-top: 20px;
    margin-left: 110px;
    margin-right: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .price-card {
      display: flex;
      justify-content: space-around;
      //   padding: 20px;
      align-items: center;

      .faq-card {
        background: #ffffff;
        margin: 15px;
        filter: drop-shadow(0 0 0.5rem crimson);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 30px;

        h3 {
          display: flex;
          justify-content: center;
          letter-spacing: 2px;
        }

        .card-list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
        }
      }
    }
  }
}

.div {
  max-width: 1120px;
  justify-content: center;
  width: 100%;

  .div-2 {
    gap: 20px;
    display: flex;
    // border: 1px solid transparent;
    border-radius: 15px;
    padding: 10px;
    // box-shadow: 0px 1px 2px 3px #767d86;
    background-color: #f4f4ef;

    .column-2 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 90%;
      margin-left: 20px;

      .div-3 {
        display: flex;
        flex-direction: column;
        margin: auto 0;

        .div-4 {
          color: var(--smartbot-framer-website-1440-x-671-default-cod-gray, #121212);
          letter-spacing: -0.4px;
          font: 600 53px/115% Inter, sans-serif;
        }
      }

    }
  }

  .course-apply-btn {
    color: var(--smartbot-framer-website-1440-x-671-default-cod-gray, #121212);
    letter-spacing: -0.4px;
    white-space: nowrap;
    font: 600 51px/119% Inter, sans-serif;
  }

  .black {
    color: white;
  }

  .white {
    color: black;
  }

  @media (max-width: 991px) {
    .course-apply-btn {
      max-width: 100%;
      font-size: 40px;
      white-space: initial;
    }
  }
}

.img {
  aspect-ratio: 0.94;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.freeweek-div-5 {
  color: var(--smartbot-framer-website-1440-x-671-default-abbey, #4a4c52);
  margin-top: 20px;
  font: 400 15px/188% Inter, sans-serif;
}



.freeweek-div-6 {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}



.free-week-tick-img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  overflow: hidden;
  max-width: 100%;
}

.div-8 {
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}


.div-9 {
  color: var(--smartbot-framer-website-1440-x-671-default-cod-gray, #121212);
  white-space: nowrap;
  font: 600 19px/149% Inter, sans-serif;
}

.div-10 {
  color: var(--smartbot-framer-website-1440-x-671-default-abbey, #4a4c52);
  font: 400 15px/189% Inter, sans-serif;
}


.dayDescriptionRow {
  align-items: flex-start;
  display: flex;
  margin-top: 9px;
  justify-content: space-between;
  gap: 6px;
}


.div-17 {
  color: var(--smartbot-framer-website-1440-x-671-default-cod-gray, #121212);
  white-space: nowrap;
  font: 600 16px/152% Inter, sans-serif;
}

.div {
  display: flex;
  width: 100%;
  gap: 30px;
  padding-top: 30px;

  .paid-week {
    align-self: stretch;
    // margin-top: 50px;
    width: 50%;

    @media (max-width:991px) {
      width: 100%;
    }

    .paid-week-div-2 {
      width: 100%;
      gap: 25px;
      display: flex;
      flex-wrap: wrap;

      // padding: 5px;
      @media (max-width:991px) {
        width: 100%;
        justify-content: center;
        flex-direction: row;
        align-items: stretch;
      }

      .column {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 27%;
        margin-left: 0px;
        border-radius: 15px;
        padding: 10px;
        background-color: #f4f4ef;


        .div-3-col {
          justify-content: center;
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-grow: 1;
          flex-direction: column;

          .div-4-icon {
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: var(--smartbot-framer-website-1440-x-671-default-cod-gray,
                #121212);
            display: flex;
            aspect-ratio: 1.525;
            flex-direction: column;
            padding: 8px;

            .paid-week-img {
              aspect-ratio: 1.88;
              object-fit: contain;
              object-position: center;
              width: 45px;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            }
          }

          .div-5 {
            justify-content: center;
            align-self: stretch;
            display: flex;
            margin-top: 20px;
            flex-direction: column;

            .div-6 {
              color: var(--smartbot-framer-website-1440-x-671-default-cod-gray, #121212);
              white-space: nowrap;
              font: 600 19px/151% Inter, sans-serif;
            }

            .div-7 {
              color: var(--smartbot-framer-website-1440-x-671-default-abbey, #4a4c52);
              margin-top: 8px;
              font: 400 15px/188% Inter, sans-serif;
            }
          }
        }
      }
    }
  }

  .demo-week {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media(min-width:991px) {
      position: sticky;
      height: fit-content;
      top: 120px;
    }

    @media (max-width:991px) {
      width: 100%;
    }

    .demoWeekDetails {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media (max-width:991px) {
        align-items: center;
      }

      .demo-week-image-number {
        background: radial-gradient(circle at center, rgb(237, 255, 204), rgb(201, 255, 102), rgb(183, 255, 51));
        width: 100%;
        aspect-ratio: 1.899/1;
        height: 150px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(0.99);
          background: linear-gradient(rgb(237, 255, 204), rgb(201, 255, 102), rgb(183, 255, 51));
        }

        @media screen and (min-width: 480px) {
          max-width: 380px;
        }

        @media screen and (max-width: 480px) {
          max-height: 100px;
        }

        @media screen and (min-width: 1010px) {
          max-width: 100%;
          max-height: 139px;
        }

        .week-number-container {
          color: #262626;
          font-size: 36px;
          font-weight: 400;
          line-height: 110%;
        }
      }

      p {
        width: fit-content;
        max-width: 100%;
        padding: 12px;
        border-radius: 30px;
        border: 1px solid #e6e9ee;
        display: flex;
        gap: 10px;
        align-items: center;
      }

      div {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        margin: 0;
      }

      span {
        font-size: 17px;
        color: #666666;
        font-weight: 400;
        margin: 0;
      }
    }

    .demoWeekInfos {
      position: relative;
      width: 100%;
      display: flex;
      padding: 10px;

      @media (max-width:991px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .demo-weel-info-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width:991px) {
          flex-wrap: wrap;
          flex-direction: row;
          max-width: 500px;
        }
      }

      .demoWeekDayInfoWrapper {
        width: fit-content;
        max-width: 100%;
        padding: 12px;
        border-bottom: 1px solid rgb(230, 233, 238);
        display: flex;
        gap: 10px;
        align-items: center;

        @media (max-width:512px) {
          width: 100%;
        }

        .demoWeekDayIcon {
          width: 20px;
          height: 20px;
        }

        .demoWeekDayInfo {
          font-size: 14px;
          color: #333333;
        }
      }

      .demoWeekInfos-glassmorphism {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba( 0, 0, 0, 0.5 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 3px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );

        .demoWeekInfos-glassmorphism-text {
          font-size: 20px;
          font-weight: 500;
          color: #fff;       
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .faqComponent {
    padding: 100px 20px 100px 20px;

    .planDescription {
      width: 90%;
    }

    .price-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      align-items: center;

      .faq-card {
        background: #ffffff;
        filter: drop-shadow(0 0 0.75rem crimson);
        width: 100%;
        padding: 30px;
      }
    }

    .planCardList {
      flex-direction: column;
      margin: 0;
      padding: 0;
      align-items: center;

      .card {
        margin: 0;
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}


@media (max-width: 991px) {
  .div {
    max-width: 100%;
    flex-direction: column;
    gap: 0;
  }

  .div-2 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }

  .column {
    width: 100%;
  }

  .img {
    max-width: 100%;
    margin-top: 40px;
  }

  .column-2 {
    width: 100%;
  }

  .div-3 {
    max-width: 100%;
    margin-top: 40px;
  }

  .div-4 {
    max-width: 100%;
    font-size: 40px;
  }

  .freeweek-div-5 {
    max-width: 100%;
  }

  .freeweek-div-6 {
    max-width: 100%;
  }

  .div-8 {
    max-width: 100%;
  }

  .div-9 {
    max-width: 100%;
    white-space: initial;
  }

  .div-10 {
    max-width: 100%;
  }

  .dayDescriptionRow {
    max-width: 100%;
    flex-wrap: wrap;
  }

  .div-17 {
    max-width: 100%;
    white-space: initial;
  }

  .paid-week {
    max-width: 100%;
    margin-top: 40px;

    .paid-week-div-2 {
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .column {
        width: 90%;

        .div-3-col {
          margin-top: 40px;

          .div-6 {
            white-space: initial;
          }
        }
      }
    }
  }
}

.tutorial-icon-container {
  color: #333333;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  border: 1px solid rgb(230, 233, 238);
  padding: 6px 10px;
  border-radius: 100px;
  background-color: rgb(248, 249, 250);
}