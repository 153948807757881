@import "../variables.scss";

.checkbox-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    gap: 10px;

    .title{
        font-size: $subHeaderFontSize;
    }
    .checkbox-label-wrapper{
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 15px;
        .checkbox-option-container{
            margin: 5px 0px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .checkbox-label-text {
                cursor: pointer;
                font-size: $mediumFontSize;
                font-weight: 500;
                margin: 0px;
            }
            .checkbox-comp-option-img{
                max-height: 1000px;
            }
        }
        .checkbox-label {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            width: fit-content;

            .checkbox-wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 4px;
                border: 2px solid $blue;
                height: 20px;
                width: 20px;
                z-index: 2;
        
                &:after {
                    content: "";
                    background: lightgray;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    padding: 10px;
                    opacity: 0;
                    transition: all 0.5s;
                    z-index: -1;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
        
                }
        
                &:hover:after {
                    opacity: 0.1;
                }
        
                &:active:after {
                    padding: 0;
                    margin: 0;
                    opacity: 0;
                    transition: 0s
                }

                .custom-checkbox {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $blue;

                    .checkmark-icon::selection {
                        background-color: transparent;
                    }
                }
        
            }
        }
    }
}