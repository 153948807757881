.profileMenuContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
    .containerImage {
      height: 35px;
      object-fit: contain;
      border-radius: 50%;
    }
  
    .optionsContainer {
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      width: max-content;
      position: absolute;
      padding: 5px;
      box-shadow: 0 0 10px 1px rgba(128, 128, 128, 0.5);
      background-color: white;
      color: black;
      font-size: 18px;
      @media (max-width: 800px){
        font-size: 14px;
        top: -18px;
        left: 100px;
      }
      @media (max-width: 578px){
        top: 40px;
        left: -25px;
      }
      .dropdownOptions {
        z-index: 10;
        width: 100%;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          background-color: rgba(210, 208, 208, 0.482);
        }
     }
     .dropdownOptionsDark{
        &:hover{
            background: #3e3e3e62;
        }
     }
    }
    .optionsContainer-dark{
      background-color: #161616;
      color: white;
      box-shadow: none;
    }
  }
  