@import "../../components/variables.scss";

.topic-subtopic-wrapper-container {
    width: 100%;
    height: 100%;
    padding: 0 15%;
    overflow: auto;

    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #767272;
    }

    &::-webkit-scrollbar-track {
        background: #2d2b2b;
    }

    .topic-subtopic-container {
        .topic-subtopic-page-header {
            padding: 5px 10px;
            position: sticky;
            top: 0;
            z-index: 1;
            border-radius: 5px;
            background-color: #2b2929;
            box-shadow: 0 0px 2px 2px #323232;
        }
    }
}