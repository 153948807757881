.question-container {
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  width: 100%;
  gap: 10px;
  .question-title-container {
    font-size: 20px;
    flex: 1;
    .question-data-label-title {
      font-size: 24px;
      font-weight: 600;
    }
    .question-data-itle {
      font-size: 24px;
      font-weight: 400;
    }
  }
  .question-content-container {
    display: flex;
    gap: 10px;
    .label-question-data {
      font-weight: 600;
    }
  }
}
