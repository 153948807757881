@import "../variables.scss";

.question-page-filter-container {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  .question-category-wrapper {
    width: 100%;
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    .question-category-card {
      background-color: $tertiaryBgColor;
      color: $secondaryColor;
      border-radius: 25px;
      padding: 10px 15px;
      cursor: pointer;
    }
    .selected-question-category-card {
      background-color: $primaryColor;
      color: $bacgroundBlackColor;
    }
  }
  .question-difficultyLevel-topic-wise-filter-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    .question-filter-selector {
      background-color: $tertiaryBgColor;
      color: $secondaryColor;
      border-radius: 5px;
      .select .options-container {
        min-width: 100%;
        width: auto;
        max-width: 300px;
        height: auto;
        max-height: 250px;
        overflow: hidden;
        .select-children-container{
          color: inherit;
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.topics-tags-list-tabs {
    gap: 5px;
    width: 100%;
    max-width: 300px;
    overflow-x: auto;
    .tab-main-container{
      padding: 5px 10px;
      min-height: auto;
      min-width: 100px;
      width: 100px;
    }
}
.tabs-main-container .tabs-children-wrapper{
  max-width: 300px;
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
}
.tab-subtopic-list-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
    padding: 5px 0px;
    .subtopic-card{
      padding: 3px 5px;
      font-size: 14px;
      border-radius: 3px;
      background-color: $gray1;
      transition: 0.5s;
      &:hover{
        background-color: $gray2;
      }
    }
    .selected-subtopic-card{
      background-color: $accent-color-9;
      color: $primaryColor;
      &:hover{
        background-color: $accent-color-10;
      }
    }
}

.options-container{
  background-color:$matteBlack;
  color: $matteWhite;
}
.select-container .select .options-container .list-child {
  border-color: transparent;
}

.select-container .select .options-container .selected-difficulty-level-option{
  background-color: $borderGrey;
  border-radius: 5px;
}