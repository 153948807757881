@import "../../components/variables.scss";

.dashboard {
  width: 100%;
  display: flex;
  height: 100vh;
  gap: 20px;
  position: relative;
  padding-left: 60px;
  .dashboard-no-data-component{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff4e;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 10; 
    display: flex;
    justify-content: center;
    // align-items: center;
    .message-container{
      font-size: 24px;
      color: $primaryTextColor;
      font-weight: 700;
      text-align: center;
      color: rgb(212, 203, 96);
    }
    
  }

  .dashboard-container {
    padding: 20px;
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
  // .dashboard-overview {
  //   width: 30%;
  //   // background-color: rebeccapurple;
  //   height: 100%;
  // }
}

@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    width: 100%;
    .dashboard-container {
      width: 100%;
      justify-content: center;
    }
    .dashboard-overview {
      width: 100%;
    }
  }
}
@media screen and (max-width: 476px) {
  .dashboard {
    margin-bottom: 90px;
    padding: 0px;
  }
}

.info-card {
  display: flex;
  align-items: center;
  background-color: #e9f0ff;
  border: 1px solid #d1e4ff;
  border-radius: 10px;
  padding: 12px;
  width: fit-content;
  max-width: 500px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position:fixed;
  left: 50%;
  top: 120px;
  transform: translate(-50%);
  @media screen  and (max-width:476px) {
     width: 90%;
  }
}

.info-icon {
  background-color: #3074f6;
  color: white;
  font-size: 24px;
  min-height: 42px;
  min-width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 12px;
}

.info-content {
  flex-grow: 1;
}

.info-message {
  margin: 0;
  font-size: 16px;
  color: rgb(56, 125, 182);
}

.info-sub-message {
  margin: 0;
  font-size: 14px;
  color: #606770;
}

.info-close {
  font-size: 20px;
  cursor: pointer;
  padding-left: 10px;
  color: #606770;
  transition: color 0.3s ease;
}

.info-close:hover {
  color: #000;
}
