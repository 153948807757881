@import "../variables.scss";

@mixin create-question-select {
  border-radius: 10px;
  border: 1px solid #fff;
  width: 240px;
  .select {
    padding: 5px;
    .select-box {
      .options-container {
        z-index: 15;
        .list-child {
          background-color: black;
          color: #fff;
        }
      }
    }
  }
}
.create-question-input-wrapper {
  display: flex;
  flex-direction: column;
  .create-question-label {
    font-size: 12px;
  }
  .create-question-input {
    .input-component-container {
      border-color: #fff;
    }
  }
  .create-question-select {
    @include create-question-select;
  }
}
.create-question-component-wrapper {
  background-color: #000;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  color: #fff;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    overflow: hidden;
    background-color: #a39c9c;
  }
  .close-button {
    position: fixed;
    color: #000;
    top: 18px;
    right: 20px;
  }
  .create-question-component-header {
    color: #fff;
    width: 100%;
  }
  .question-meta-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    border-radius: 20px;
    padding: 10px;
    flex: 1;
  }
  .break-line {
    border-top: 1px solid #fff;
  }
  .create-question-select {
    @include create-question-select;
  }
  .marks-questionType-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 20px;
  }
  .accordian-container {
    padding: 10px;
    .add-option-container {
      width: 100%;
    }
    .accordion-header {
      padding: 10px 0px;
      color: #fff;
    }
    .accordion-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .add-option-container {
        width: 100%;
        display: flex;
        justify-content: right;
      }
    }
  }
}
.select-accordian-container {
  width: 240px;
  .accordion-header .title-subtitle .accordion-title {
    font-size: 16px;
    padding: 10px 10px;
  }
  .open-accordion {
    padding: 10px;
  }
  .checkbox-container {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #a39c9c;
    }
  }
}
.description-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.description-text-conatiner {
  color: white;
  background-color: #000;
  border: 1px solid lightslategray;
  column-span: 10;
  padding: 10px;
  width: 100%;
  resize: vertical;
  border-radius: 10px;
}
.create-question-tags-header-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.create-question-tags-children-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border-top: 1px solid $gray;
  padding-top: 5px;
}
.create-question-tag-header {
  padding: 2px 3px;
  color: $primaryColor;
  transition: 0.5s;
  cursor: pointer;
}
.create-question-tag-header-selected {
  color: $blue;
  transform: scale(105%);
  font-weight: 600;
}
.create-question-tags-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.create-question-tag-card {
  padding: 3px 5px;
  font-size: $smallFontSize;
  background-color: $Gray35;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  &.selected {
    background-color: $blue;
  }
}
.create-question-selected-tags-preview {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  background-color: $Gray35;
  border-radius: 5px;
  padding: 5px 0px;
  .create-question-selected-tags-wrapper {
    display: flex;
    gap: 2px;
    padding: 0px 3px;
    border-radius: 5px;
    align-items: center;
    background-color: $blue;
    .create-question-selected-tag {
      font-size: 14px;
    }
    .CloseCrossIcon-wrapper {
      background-color: $darkBlue;
      border-radius: 50%;
      padding: 1px;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
