.home-page-header-section-container {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;

    .home-page-background {
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, - 50%);
    }

    .header-page-container-box {
        width: 100vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, - 50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .beWebMaster-tag {
            background: linear-gradient(to bottom, #ABFF1C, #e6ffbb);
            color: black;
            font-size: 15px;
            padding: 0 20px 0 20px;
            border-radius: 50px;
            font-weight: 500;
            margin: 30px;
        }

        .success-story-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #dfdfdf;
            background-color: #F8F9FA;
            width: 270px;
            position: relative;
            padding: 0 10px 0 10px;
            border-radius: 50px;

            .avator1 {
                border: 2px solid white;
                width: 30px;
                height: 30px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: pink;
                overflow: hidden;
            }

            .avator2 {
                border: 2px solid white;
                width: 30px;
                height: 30px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: orange;
                position: absolute;
                left: 7%;
                overflow: hidden;
            }

            .avator3 {
                border: 2px solid white;
                width: 30px;
                height: 30px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: green;
                position: absolute;
                left: 11%;
                overflow: hidden;
            }
            .avator-img{
                height: 100%;
                height: 100%;

            }


            .text {
                margin-left: 10px;
                font-size: 15px;
            }

            .join-now-button {
                padding: 0 10px 0 10px;
                background: linear-gradient(to bottom, #ABFF1C, #e6ffbb);
                border-radius: 50px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: black;
                cursor: pointer;

                svg {
                    margin-left: 3px;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .header-page-main-heading {
            margin-top: 10px;
            text-align: center;
            
            .header-page-main-heading-h1 {
                color: black;
                font-size: 40px;
                font-weight: 700;
                line-height: 30px;
            }
        }

        .header-page-main-subheading {
            margin-top: 20px;
            width: 700px;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            color: black;

        }

        .header-page-join-weebinar-button {
            margin-top: 40px;
            background: linear-gradient(to bottom, rgb(24, 24, 24), rgb(129, 129, 129));
            color: white;
            font-size: 30px;
            padding: 5px 20px 5px 20px;
            border-radius: 10px;
            cursor: pointer;

        }

    }

    .auto-nav-container {
        display: flex;
        overflow: hidden;
        position: relative;
        z-index: 2;
        margin-top: 75vh;
        display: flex;
        align-items: end;
        justify-content: center;

        .box-shadow {
            width: 300px;
            height: 100px;
            top: -10px;
            position: absolute;
            z-index: 1;
        }

        .left-box {
            left: 0%;
            background: linear-gradient(to right, #F8F9FA 30%, rgba(255, 255, 255, 0));

        }

        .right-box {
            right: 0%;
            background: linear-gradient(to left, #F8F9FA 30%, rgba(255, 255, 255, 0))
        }
    }
}

@media (max-width: 1200px) {
    .home-page-header-section-container {
        .auto-nav-container {
            .box-shadow {
                width: 200px;
            }

            .left-box {
                background: linear-gradient(to right, #F8F9FA 30%, rgba(255, 255, 255, 0));
            }

            .right-box {
                background: linear-gradient(to left, #F8F9FA 30%, rgba(255, 255, 255, 0));
            }
        }
    }
}

@media (max-width: 992px) {
    .home-page-header-section-container {
        .auto-nav-container {
            .box-shadow {
                width: 200px;
            }

            .left-box {
                background: linear-gradient(to right, #F8F9FA 30%, rgba(255, 255, 255, 0));
            }

            .right-box {
                background: linear-gradient(to left, #F8F9FA 30%, rgba(255, 255, 255, 0));
            }
        }
    }
}


@media (max-width: 768px) {
    .home-page-header-section-container {
        .header-page-container-box {

            .header-page-main-heading {
                margin-top: 25px;
                width: 530px;
                .header-page-main-heading-h1 {
                    font-size: 30px;
                    line-height: 20px;
                }
            }

            .header-page-main-subheading {
                width: 500px;
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 576px) {
    .home-page-header-section-container {
        .header-page-container-box {
            .header-page-main-heading {
                margin-top: 25px;
                width: 480px;
            }

            .header-page-main-subheading {
                width: 400px;
                font-size: 20px;
            }
        }

        .auto-nav-container {
            .box-shadow {
                width: 150px;
            }

        }
    }
}

@media (max-width: 480px) {
    .home-page-header-section-container {
        .header-page-container-box {
            .header-page-main-heading {
                margin-top: 25px;
                width: 400px;
                font-size: 30px;
            }

            .header-page-main-subheading {
                width: 380px;
                font-size: 20px;
            }
        }

        .auto-nav-container {
            margin-top: 75vh;

            .box-shadow {
                width: 100px;
            }

        }

    }

}