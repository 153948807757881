@import "../variables.scss";
.upload-image-container {
  width: 120px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: transparent;
  border: 6px solid #F8F9FA;
  box-shadow:  0 0 10px 0 rgba(161, 161, 161, 0.671)  ;
  color: $primaryBgColor;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  .upload-image-label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: $smallFontSize;
    .upload-image-text-and-icon {
      display: flex;
      flex-direction: column;
      .upload-image-label-text {
        width: min-content;
        text-align: center;
      }
    }
    .upload-image-preview-show {
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      border-radius: 50%;
      box-shadow: 0px 0px 10px 1px $primaryBgColor;
      transition: 0.7s;
      &:hover {
        transform: scale(105%);
      }
    }
  }
}
