.course-info-card-container{
        width: 100%;
        min-width: 300px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border: 1px solid rgba(128, 128, 128, 0.203);
        background-color: white;
        border-radius:20px;
        box-shadow: 3px 3px 16px -10px gray;
        transition: box-shadow 0.3s;
    @media (max-width: 991px) {
        max-width: 380px;
        min-width: 350px;
    }

    @media (max-width: 510px) {
        max-width: 370px;
        min-width: 100%;
    }

    .course-info-sub-container {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        .title-text {
            font-size: 20px;
            color: #262626;
        }

        .icon-container {
            width: 48px;
            height: 48px;
            background-color: rgb(219, 255, 153);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
        }

        .icon-url {
            width: 100%;
            height: 100%;
            object-fit: contain;
            overflow: hidden;
        }
    }

    .description-text {
        white-space: pre-wrap;
        width: 100%;
        font-size: 15px;
        word-break: break-word;
        word-wrap: break-word;
        color: #606060;

        @media (max-width: 991px) {
            flex-direction: column;
            text-align: center;
        }
    }
}

.active-course-info-card-container {
    box-shadow: 3px 3px 16px -10px gray;

}