@import "../variables.scss";
.installment-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 4px;
  align-items: center;
  justify-content: center;
  .image-preview-outer-container {
    position: relative;
    .cross-icon-container {
      cursor: pointer;
      position: absolute;
      top: -9px;
      right: -10px;
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: none;
      z-index: 10;
    }
    .image-preview-container {
      width: 120px;
      height: 150px;
      border: 4px solid $lightBlack;
      border-radius: 6px;
      overflow: hidden;

      .receipt-preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .installment-amount {
    font-weight: 600;
    color: $installmentPagePrimaryFont;
    font-size: $titleFontSize;
    .amount-container {
      font-size: 40px;
    }
  }

  .installment-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .installement-info-container {
      display: flex;
      flex-direction: column;
      .installment-elem {
        color: $installmentPageSecondaryFont;
        font-size: $smallFontSize;
      }
    }
  }
  .file-input-wrapper {
    .file-input {
      display: none;
    }
    .file-input-label {
      padding: 6px 15px;
      background-color: $installmentPageButton;
      border-radius: 3px;
      font-size: $smallFontSize;
      color: $lightBlack;
      font-weight: 700;
      &:hover {
        background-color: #5b5959;
        border-radius: 6px;
        color: $primaryColor;
      }
      cursor: pointer;
      &.disabled {
        background-color: $Gray29;
        cursor: not-allowed;
        color: $sencondaryTextColor;
      }
    }
  }
}

.pay-now-button {
  border: none;
  padding: 6px 15px;
  font-size: $smallFontSize;
  color: $lightBlack;
}

.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: $sencondaryBorderColor;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $screenXs) {
    width: 95vw;
  }
  .preview-image {
    max-width: 85%;
    max-height: 85%;
    border: 1px solid $primaryColor;
    border-radius: 4px;
  }
}
