@import "../variables.scss";

.user-status-card {
  display: flex;
  gap: 5px;
  padding: 0 5px;
  border-radius: 6px;
  background-color: $secondaryBgColor;
  height: min-content;
  width: fit-content;
  box-shadow: $box-shadow-light-dark 0px 0.301094px 0.301094px 1px;
  transition: all 1s;
  &:hover {
    background-color: $Gray43;
  }
  .content-text {
    padding: 5px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
  }
  .title,
  .goal-text {
    width: 240px;
  }
  .tagName {
    .status-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dotted $glowingYellow;
      color: $glowingYellow;
      font-size: $extraSmallFontSize;
      border-radius: 3px;
    }
    padding: 10px 20px;
  }
  .batchCode {
    width: 150px;
  }
  .name,
  .tagName,
  .time {
    width: 110px;
  }

  .goal-status-button {
    min-width: 100px;
    height: 30px;
    border: none;
    box-shadow: $boxShadowSecondaryColor 0px 0.301094px 0.301094px 1px;
    color: $primaryColor;
    padding: 6px 8px;
    background-color: $bgColorSecondary;
    font-size: $smallFontSize;
    transition: all 1s;
    &.reject-button {
      &:hover {
        background-color: $red;
      }
    }
    &.approve-button {
      &:hover {
        background-color: $green;
      }
    }
  }
}
