
.auto-slider-container {
  display: flex;
  width: 1200px;
  padding: 30px 0;
  overflow: hidden;
  position: relative;
  .shadow {
    width: 200px;
    height: 100px;
    top: -10px;
    position: absolute;
    z-index: 1;
  }
  .left {
    left: 0%;
  }
  .right {
    right: 0%;
  }
  .auto-slider {
    display: flex;
    .stacks {
      display: flex;
      align-items: center;
      justify-content: last baseline;
      margin: 0 30px;
      font-weight: bolder;
      .stack-logo {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    animation: 40s scroll infinite linear;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 992px) {
  .auto-slider-container {
    width: 760px;
  }
}

@media (max-width: 768px) {
  .auto-slider-container {
    width: 576px;
  }
}

@media (max-width: 576px) {
  .auto-slider-container {
    width: 486px;
  }
}

@media (max-width: 480px) {
  .auto-slider-container {
    width: 320px;
  }
}