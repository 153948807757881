.apply-now-button-container{
    background-color:  #ED303C;
    color: white;
    font-weight: 500;
    padding: 12px 18px 12px 18px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width: 992px) {
    .apply-now-button-container{
        background-color:  #ED303C;
        color: white;
        font-weight: 500;
        padding: 9px 18px 9px 18px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
    }


}

@media (max-width: 768px) {
    .apply-now-button-container{

    padding: 5px 16px 5px 16px;
    }
}

@media (max-width: 576px) {

}

@media (max-width: 480px) {

}