.dashboard-assignment-component-container{
  width: 380px;
  max-height: 360px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #2B2F33;
  @media screen and (max-width:476px) {
    width: 100%;
    max-width: 380px;
  }
  .current-assignment-header{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #f9f8fa;
    font-size: 14px;
    font-weight: 600;
  }
  .task-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    max-width: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(66, 66, 66, 0.4);
    border-radius: 8px;
    justify-content: space-between;
    transition: all 0.3s ease;
    gap: 4px;
    &:hover {
      background: rgba(66, 66, 66, 0.7);
    }
  }
  
  .task-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #f9f8fa;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
  
  .task-icon {
    margin-right: 10px;
  }
  
  .task-date {
    color: #6c757d;
  }
  .status-date-container{
    display: flex;
    gap: 10px;
  }
  
  .task-status {
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: bold;
    text-align: center;
    min-width: 80px;
    font-size: 10px;
  }
  
  .task-status.upcoming {
    background-color: #ecdc9d73;
    color: orange;
  }
  
  .task-status.pending {
    background-color: #f7c6c5;
    color: #d9534f;
  }
  
  .task-status.submitted {
    background-color: #d4edda;
    color: green;
  }
  
  .task-status.graded {
    background-color: #ffeeba;
    color: #856404;
  }
  
}