.discussion-page-container {
    height: 80vh;
    width: 65vw;
    margin: auto;
    border: 1px solid rgba(256, 256, 256, 0.2);
    display: flex;
  
    .discussion-page-chat-message {
      height: 100%;
      overflow: scroll;
      position: relative;
      width: 100%;
      background-color: rgb(120, 120, 120, 0.1);
      .discussion-chat-input-container {
        position: absolute;
        bottom: 16px;
        left: calc(50% - 16px);
        width: calc(100% - 32px);
        transform: translate(calc(-50% + 16px), 0%);
        background-color: rgba(0, 0, 0, 1);
      }
      .discussion-page-channel-header {
        display: flex;
        align-items: center;
        gap: 16px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 8px 16px;
        box-shadow: 0 0 2px 2px rgba(256, 256, 256, 0.2);
      }
      .discussion-chat-message-main-container {
        padding-top: 50px;
        padding-bottom: 16px;
        height: calc(60vh - 16px);
        overflow-x: scroll;
        .discussion-page-day-chat-container {
          min-height: 10px;
          width: 100%;
          border-top: 1px solid rgba(256, 256, 256, 0.2);
          position: relative;
          .discussion-message-day-date-header {
            height: fit-content;
            padding: 8px 16px;
            width: fit-content;
            margin: auto;
            border: 1px solid rgba(256, 256, 256, 0.2);
            border-radius: 25px;
            position: absolute;
            top: 0%;
            left: 50%;
            font-size: 12px;
            font-weight: 800;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.2);
          }
          .discussion-chat-message-container {
            padding: 16px;
            display: flex;
            gap: 16px;
            &:hover {
              background-color: rgba(0, 0, 0, 0.2);
            }
            .discussion-chat-message-content-wrapper {
              .discussion-chat-message-user-heading {
                font-weight: 900;
                font-size: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: fit-content;
              }
            }
            .discussion-chat-files-container{
              display: flex;
              flex-wrap: wrap;
              padding: 16px;
              gap: 16px;
              .discussion-chat-file {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
  
  .discussion-chat-message-content {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
  }
  
  .chat-message-time {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    margin-left: 5px;
  }