@import '../../components/variables.scss';

.no-data-container {
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $titleFontSize;
  .user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 80%;
    margin: 0;
  }
}