@import "./components/variables.scss";

#zmmtg-root{
    display: none;
    z-index: 7;
}

// this line will hide zoom warnings on UI
iframe#webpack-dev-server-client-overlay{
    display: none;
}

html, body{
    overflow: hidden;
    min-width: 100vw !important;
}
.app-container{
    min-height: 100vh;
    width: 100vw;
    color: $primaryTextColor;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    display: flex;
    &::-webkit-scrollbar{
        display: none;
    }
    .app-section{
        width: 100vw;
        height: 100vh;
        z-index: 0;
        overflow-x: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .portal-app-section{
        background-color: $primaryBgColor;
    }
    .app-log-out{
        background-color: $primaryBgColor;
    }
}

@media screen and (max-width: $screenXs) {
    .app-container{
        .portal-app-section{
            height: calc(100vh - 90px);
        }
    }
}