.leftRegisterPage {
  width: 25%;
  height: 98vh;
  margin: 1vh;
  border-radius: 8px;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;

  .back-button {
    position: fixed;
    left: -1%;
    top: 1%;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    .saveFormButton {
      margin-top: 10vh;

      .saveButton {
        background: white;
        border-radius: 8px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }



    .heading {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      justify-content: space-around;
      gap: 10px;

      .image-delete-button {
        font-size: 14px;
        color: black;
        padding: 5px 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(161, 161, 161, 0.671)
      }

      .mobileScreen.saveFormButton {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .leftRegisterPage {
    width: 97%;
    height: 10vh;
    border-radius: 8px;

    .back-button {
      position: fixed;
      left: -3%;
      top: 1%;
      cursor: pointer;
    }

    .container {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0;
      margin-top: 8vh;

      .heading {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: end;
      }

      .steps {
        display: flex;
        grid-gap: 2rem;
      }
    }
  }
}