.glassmorphism {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba( 0, 0, 0, 0.5 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

    .glassmorphism-text {
      font-size: 20px;
      font-weight: 500;
      color: #fff;       
    }
  }