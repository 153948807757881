@import "../variables.scss";

.dashboard-question-component-container{
  width: 380px;
  max-height: 260px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #2B2F33;
  .current-question-header {
    color: #f9f8fa;
    font-size: 14px;
    font-weight: 600;
  }
  .current-class-wrapper{
    display: flex;
    gap: 15px;
    align-items: flex-start;
    padding: 8px;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .question-dashboard-chart-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .question-dashboard-chart-text-single-row {
          width: 100%;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;
          .question-dashboard-chart-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
            .question-dashboard-chart-text-dot {
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 5px;
            }
            .question-dashboard-chart-text-label {
              font-size: 12px
            }
          }
        }
      }
  }
}
.pie-chart-container{
    width: 200px;
    height: 200px;
    .dashboard-chart{
        width: 190px;
        height: 190px;
    }

}